import { Component, Input } from '@angular/core';
import { CrudService } from 'src/app/services/crud.service';
import { environment } from 'src/environments/environment';
import { LoadingService } from '../report-loading.service';

@Component({
    selector: 'app-promotion-details-export',
    templateUrl: './promotion-details-export.component.html',
    styleUrls: ['./promotion-details-export.component.css'],
    standalone: false
})
export class PromotionDetailsExportComponent {
  @Input() name: string;
  @Input() selectedFilter: string = '';
  filtered_by: string = '';
  promotionDetailsData: any;
  apiUrl: string = environment.baseApiUrl;

  constructor(private crudService: CrudService, private loadingService:LoadingService) { }


handleSelectedFilter(filter: string) {
  this.selectedFilter = filter;
  }
  buyingGroupName:string = '';
  promotionName:string = '';
  async getPromoData(e: any) {
   
    if (!e.filtered) {
      return;
    }
    this.promotionName = e.promotionNameSelected;
    this.buyingGroupName = e.groupSelectedName;
    this.filtered_by = e.filtered.buying_group_name;
    
    await this.crudService.getExportData(
      `${this.apiUrl}export/promotion-details?promotion_period_id=${e.promotionSelected.value}&buying_group_id=${e.filtered.id}`,
      {},
      'blob'
    ).subscribe(
      (response: Blob) => {
            // Create a Blob from the response
            const blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

            // Create a download link
            const downloadLink = document.createElement('a');
            const url = window.URL.createObjectURL(blob);
            downloadLink.href = url;
            downloadLink.download = 'Promotion Details Export.xlsx';
            downloadLink.download = 'Promotion_Details_Export_' + this.promotionName + '_' + this.buyingGroupName + '.xlsx';
            // Append the link, trigger the download, then remove the link
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
            window.URL.revokeObjectURL(url);
            this.loadingService.toggleLoading(false);
        },
        (error:any) => {
            console.error('Error:', error);
        }
    );
  }

}
