import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, tap, Observable, take, Subject, map, of, throwError, switchMap, timeout, timeInterval } from 'rxjs';
import { Response } from './user.model';
import { UserDetails } from './models/user-details';
import { environment } from 'src/environments/environment';
import themes from 'devextreme/ui/themes';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard  {

  private authApiUrl: string = environment.authApiUrl;
  private authUiUrl: string = environment.authUiUrl;
  private isLocal: boolean = environment.isLocal;

  constructor(
    private cookieService: CookieService,
    private http: HttpClient) {}

  logOut() {
    const token = sessionStorage.getItem('token') ? sessionStorage.getItem('token') : '';
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + token
    });
    this.http.get(`${this.authApiUrl}logout`, { headers })
      .subscribe(response => {
        console.log(response);
        this.cookieService.deleteAll();
        window.location.replace(this.authUiUrl);
      },
      (error) => {
        console.error(error);
      });
  }

  userDetails(): Observable<UserDetails> {
    let tokenDetails: any = {};
    if(this.cookieService.get('otd-user')) {
      tokenDetails = JSON.parse(this.cookieService.get('otd-user'));
    } else {
      const userDetailsString = sessionStorage.getItem('user') || '';
      const userDetails = JSON.parse(userDetailsString);
      tokenDetails.email = userDetails.email;
      tokenDetails.token = sessionStorage.getItem('token') || '';
    }
    console.log('User details:', tokenDetails);
    if (!tokenDetails) {
      // Handle the case where the cookie is not set or is empty
      console.error('User details cookie is missing or empty');
      return of({ data: {} } as UserDetails); // Return an empty UserDetails object or handle as needed
    }
    const url = `${this.authApiUrl}user-details?email=${tokenDetails.email}`;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + tokenDetails.token
    });
    return this.http.get<UserDetails>(url, { headers, withCredentials: true }).pipe(
      tap(data => {
      themes.current(environment.themes[data.data.theme]);
      document.body.style.backgroundColor = environment.bgColours[data.data.theme];
      localStorage.setItem('userDetails', JSON.stringify(data));
      }),
      take(1)
    );
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.verifyToken().pipe(
      switchMap(isAuthenticated => {
        if (!isAuthenticated) {
          window.location.href = environment.authUiUrl;
          return of(false);
        }

        // const requiredPermission = route.data['requiredPermission'];
        // if (requiredPermission && !this.hasPermission(requiredPermission)) {
        //   this.router.navigate(['/access-denied']);
        //   return of(false);
        // }

        return of(true);
      })
    );
  }

  isAuthenticated(): Observable<boolean> {
    return this.verifyToken().pipe(
      map(isValid => {
        return isValid;
      })
    );
  }

  verifyToken(): Observable<boolean> {
    return this.http.post<IResponse>(
        `${environment.authApiUrl}verify`, 
        {}, 
        { withCredentials: true } // Ensure credentials (cookies) are sent with the request
      )
      .pipe(
        map(res => {
          // Log the entire response for debugging
          console.log('Received response from /verify:', res);
          
          if (res.success) {
            console.log('Token is valid.');
            if (typeof res.data.token === 'string') {
              sessionStorage.setItem('token', res.data.token);
              sessionStorage.setItem('user', JSON.stringify(res.data.user));
            } else {
              console.error('Token is not a string:', res.data.token);
            }
            return true;
          } else {
            console.log('Token is invalid.');
            return false;
          }
        }),
        catchError((error) => {
          // Log the error response for debugging
          console.log('Verification failed:', error);
          return of(false);
        })
      );
  }
  
  userNameChanged = new Subject<string>();
  updateUserDetails(details: UserDetails, newPassword?:string): Response {
    console.log("Update User Details");
    const newUserName = details.data.username;
    this.userNameChanged.next(newUserName);
    const token = sessionStorage.getItem('token') ? sessionStorage.getItem('token') : '';
    let headers = new HttpHeaders({
      'Authorization': 'Bearer ' + token
    });
    if (token) {
      const payload = newPassword ? { ...details.data, password: newPassword } : details.data;
      this.http.put<Response>(`${this.authApiUrl}update-profile`, payload, { headers })
   .pipe(
     tap(res => {
       if(res.success) {
         console.log('Details Updated');
         console.log(res);
       }
       return res;
     }),
     catchError(error => {
       console.error('Failed to update user details:', error);
       return throwError(() => new Error('Failed to update due to server error'));
     })
   ).subscribe({
     next: (response) => {
       // Handle successful response
     },
     error: (error) => {
       // Properly handle the error scenario
       console.error('Update failed:', error);
     }
   });

    }
    return {success: false, message: 'Token is missing'};
  }

  userName(): string {
    return this.cookieService.get('otd-userName');
  }
  userCookieDetails(): object {
    if(this.cookieService.check('otd-user')) {
      return JSON.parse(this.cookieService.get('otd-user'));
    }
    return {};
  }
 
  verifyLoginDetails(email: string, password: string): Observable<boolean> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    return this.http.post<ILogin>(`${this.authApiUrl}login`, { email: email, password: password }, httpOptions)
      .pipe(
        map(response => true), // Assuming the request was successful and we got a 200 status
        catchError(error => {
          // You can check for specific status codes here if you want to differentiate
          console.error('Login failed', error);
          return of(false); // Return Observable of false
        })
      );
  }
}
const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
}
export interface ILogin {
  success: boolean;
  data: {
    token: string;
    name: string;
  };
  message: string;
}

export interface IResponse {
  success: boolean;
  data: any;
  message: string;
}
