import { Component, Output, EventEmitter, Input, ViewChild } from '@angular/core';
import { HorizontalMenuService } from 'src/app/services/horizontalmenu.service';
import { ConfirmationService } from 'src/app/services/confirmation.service';
import CustomStore from 'devextreme/data/custom_store';
import { CrudService } from 'src/app/services/crud.service';
import { environment } from 'src/environments/environment';
import { DxListComponent } from 'devextreme-angular';
import { confirm } from 'devextreme/ui/dialog';

@Component({
    selector: 'app-mix-match-qty',
    templateUrl: './mix-match-qty.component.html',
    styleUrls: ['./mix-match-qty.component.css'],
    standalone: false
})
export class MixMatchQtyComponent {
  editMixMatchQty: boolean = false;
  buttonDisabled: boolean = false;
  mixMatchList: CustomStore;
  private apiUrl: string = environment.baseApiUrl;
  @Input() productId: number;
  @Output() qty = new EventEmitter<number>();
  @Output() price = new EventEmitter<number>();
  @Output() close = new EventEmitter<boolean>();
  @Output() importAllCheckbox = new EventEmitter<boolean>();
  @Output() nameFieldEmitter = new EventEmitter<string>();
  @Output() mixMatchHeaderId = new EventEmitter<number>();
  @Output() mixMatchHeaderName = new EventEmitter<string>();
  @Output() quickAdd = new EventEmitter<boolean>();
  @ViewChild("dataList", { static: false }) public dataList: DxListComponent;
  qtyValue: number;
  nameField: string;
  constructor(private horizontalMenuService: HorizontalMenuService,
    private confirmationService:ConfirmationService,
    private crudService: CrudService) {
    this.horizontalMenuService.editMixMatchQty$.subscribe((value: boolean) => {
      this.editMixMatchQty = value;
      console.log('EditMixMatchQty: ', this.editMixMatchQty);
    });
  }

  onShowing(event: any) {
    console.log('Event: ', event);
    console.log('ProductId: ', this.productId);
    this.mixMatchList = new CustomStore<any>({
      key: 'id',
      load: async () => {
        return await new Promise((resolve) => {
          this.crudService.getData(`${this.apiUrl}mix-match-headers/${this.productId}`).subscribe((result: any) => {
            resolve({data: result, totalCount: result.length});
          })
        })
      }
    });
  }

  onSelect(e: any) {
    console.log('Selected: ', e);
    this.setPrice({value: e.itemData.total_price});
    this.setMixMatchHeaderId({value: e.itemData.id});
    this.setMixMatchHeaderName({value: e.itemData.name});
    confirm('Are you sure you want to select this item?', 'Confirm selection').then((result: any) => {
      if (result) {
        this.quickAdd.emit(true);
      }
    });
  }
  setCheckbox(e:any){
    this.importAllCheckbox.emit(e.value);
  }
  setQty(e: any) {
    this.qty.emit(e.value);
  }

  setPrice(e: any) {
    this.price.emit(e.value);
  }

  setMixMatchHeaderId(e: any) {
    this.mixMatchHeaderId.emit(e.value);
  }

  setMixMatchHeaderName(e: any) {
    this.mixMatchHeaderName.emit(e.value);
  }

  closePopup() {
    this.nameFieldEmitter.emit(this.nameField);
    this.close.emit(true);
    this.horizontalMenuService.seteditMixMatchQty(false);
  }


  onHiding(event: any) {
    this.horizontalMenuService.seteditMixMatchQty(false);
  }

}
