import { Component, Input } from '@angular/core';
import { PromotionDetails } from 'src/app/models/promotion-details.model';
import { PromotionHeader } from 'src/app/models/promotion-header.model';
import { CrudService } from 'src/app/services/crud.service';
import { environment } from 'src/environments/environment';

import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { PageOrientation } from 'pdfmake/interfaces';
import { PageSize } from 'pdfmake/interfaces';
import { StyleDictionary } from 'pdfmake/interfaces';
import { GroupProductService } from '../../group-products-service.service';
import { Base64Images } from 'src/app/services/base64-images.service';
import { DateFormatService } from 'src/app/services/date-format.service';
import { ReportStylesService } from 'src/app/services/report-styles.service';
import { LoadingService } from '../../report-loading.service';
import notify from 'devextreme/ui/notify';
import { DateService } from 'src/app/services/date.service';
const pdf = pdfMake;
pdf.vfs = pdfFonts.vfs;
@Component({
    selector: 'app-pos-press-brief-report-thirsty',
    templateUrl: './pos-press-brief-report-thirsty.component.html',
    styleUrls: ['./pos-press-brief-report-thirsty.component.css'],
    standalone: false
})
export class PosPressBriefReportThirstyComponent {
  @Input() name: string;
  @Input() reportComponent: string;
  private mediaPlanningUrl: string = environment.baseApiUrl + 'media-planning';
  private promoDetailsUrl: string = environment.baseApiUrl + 'promotion-details';
  data: any[];
  headers: PromotionHeader[];
  promotionName: string = '';
  pageNumber: number = 1;
  userDetails: any;
  dynamicColumns: any = [];
  standardKeys: any = [];
  mediaPlanData: any = [];
  disclaimer:string;
  constructor(private dateService:DateService,
              private crudService: CrudService,
              private groupProductService: GroupProductService,
              private loadingService: LoadingService,
              private reportStylesService: ReportStylesService,
              private base64Image: Base64Images,
              private dateFormatService: DateFormatService)
  {

    const userDetailsData = localStorage.getItem("userDetails");
    if (userDetailsData) {
      this.userDetails = JSON.parse(userDetailsData || '{}');

  } else {
      // Handle the null case, e.g., set to a default value or log an error
      this.userDetails = {}; // or some other default value
  }

    //
  }

  pagePromotion: string = '';
  promotionIndex: number = 0;

transformDataForPdfMake2(groupedMediaPlans: any[]): any[] {
  if (!groupedMediaPlans || groupedMediaPlans.length === 0) {
    console.error('No valid media plans available to transform.');
    return [];
}

let tables: any = [];

const excludedKeys = [
  'promotion_group_id', 'promotion_header_id', 'promotion_name', 'promotion_period_id',
  'slot_type', 'store_position_name', 'promotion_start', 'promotion_end', 'planning_category_name',
  'buying_group_name', 'linked_promotion_period_id', 'edlp'
];
const headerMapping = {
    promotion_group_name: 'Group Name',
    price: 'Price',
    TBA: 'Additional Info'
};

groupedMediaPlans.forEach(promotion => {
  const promotionName = promotion.promotionName;
  const promoDateRange = `${this.dateFormatService.changeDateFormat(promotion.promotion_start)} - ${this.dateFormatService.changeDateFormat(promotion.promotion_end)}`;
  const products = promotion.products;

  if (!products || Object.keys(products).length === 0) {
      console.error(`No products available for promotion: ${promotionName}.`);
      return;
  }

  let allRows:any = [];  // Collect all product details for this promotion in one array

  let combinedHeaders:any = { ...headerMapping };  // Start with static headers
  let a4Total = 0;
  let c8Total = 0;
  // Collect all unique dynamic headers from all product details first to ensure consistency
  Object.entries(products).forEach(([productName, productDetails]: any) => {
    productDetails.forEach((detail: any) => {
      Object.keys(detail).forEach(key => {
        if (!excludedKeys.includes(key) && !Object.keys(headerMapping).includes(key) && detail[key] !== undefined && !combinedHeaders.hasOwnProperty(key)) {
          combinedHeaders[key] = key.replace('_', ' ');
        }
        if (key === 'A4' && detail[key] !== undefined) {
          a4Total += parseFloat(detail[key]);
        }
        if (key === 'C8' && detail[key] !== undefined) {
          c8Total += parseFloat(detail[key]);
        }
      });
    });
  });

  const headerOrder = Object.keys(combinedHeaders);
  const styledHeaders = headerOrder.map(header => ({ text: combinedHeaders[header], style: 'tableHeader'}));
console.log(styledHeaders)
  // Now map the details to headers
  Object.entries(products).forEach(([productName, productDetails]: any) => {
    productDetails.forEach((detail: any) => {
      const row = headerOrder.map(header => {
        let value = detail[header];
        value = value === null || value === undefined ? '' : value.toString();

        if(header === 'promotion_group_name'){
          return { text: value, style: 'bodyStyle', alignment: 'left' };
        }
        return { text: value, style: 'bodyStyle', alignment: 'center' };
      });
      allRows.push(row);
    });
  });

  // Construct the table only once per promotion
  const subHeaderRow = [{
      text: `${promotionName} (${promoDateRange})`,
      style: 'subheaderStyle',
      colSpan: headerOrder.length,
      alignment: 'left'
  }];

  // Optionally, add rows to show totals
  const totalsRow = headerOrder.map(header => {
    if (header === 'A4') return { text: `Total: ${a4Total}`, style: 'bodyStyle' };
    if (header === 'C8') return { text: `Total: ${c8Total}`, style: 'bodyStyle' };
    return { text: '', style: 'bodyStyle' };
});
// Calculate widths: specific widths for static fields, '*' for dynamic fields
const widths = headerOrder.map(header => {
  if (header in headerMapping) {
    switch (header) {
      case 'promotion_group_name':
        return '*';
        case 'price':
          return 'auto'; // Short width for price
          default:
            return '*'; // Default width for known static fields
          }
        } else {
          return 'auto'; // Assign '*' to dynamic fields
        }
      });

       if(this.promotionIndex < (groupedMediaPlans.length - 1)){
        this.promotionIndex++
        tables.push({
            table: {
                headerRows: 2,
                widths: widths,
                body: [styledHeaders, subHeaderRow, ...allRows, totalsRow] // Add totalsRow here
            },
            layout: {
                hLineWidth: () => 0,
                vLineWidth: () => 0,
                hLineColor: () => 'white',
                vLineColor: () => 'white',
                fillColor: function (rowIndex: number, node:any, columnIndex:any) {
                    return (rowIndex % 2 === 0) ? '#d9f0f1' : null;
                }
            },
            pageBreak: 'after'
        });
      }

      else{
        tables.push({
          table: {
              headerRows: 2,
              widths: widths,
              body: [styledHeaders, subHeaderRow, ...allRows, totalsRow] // Add totalsRow here
          },
          layout: {
              hLineWidth: () => 0,
              vLineWidth: () => 0,
              hLineColor: () => 'white',
              vLineColor: () => 'white',
              fillColor: function (rowIndex: number, node:any, columnIndex:any) {
                  return (rowIndex % 2 === 0) ? '#d9f0f1' : null;
              }
          },

      });
      }
    });
    const subheader = {
      stack: [
        {
          columns: [
            {
              text: `${this.name}`,
              style: 'header',
              alignment: 'left',
            },
            {
                image: this.base64Image.getBase64Image(this.userDetails.data.location_token),
                width: 150,
                alignment: 'right',
                margin: [0, 0, 0, 0]  // Adjust margin as needed for the image
            }

          ]
        }


        ]
      };
    const secondHeader = {
    stack: [
    {
    columns: [
    {
      text: `${this.filtered_by_promotion}`,

      style: 'subheader',
      alignment: 'left',
      margin: [10, 0, 0, 0]  // Adjust margin as needed for the text
    },
    {
      text: `${this.filtered_by}`,

      style: 'subheader',
      alignment: 'right',
      margin: [10, 0, 0, 0]  // Adjust margin as needed for the text
    }
    ]
    }
    ]
    }

    return [  subheader,
      secondHeader,
      tables];
}


styleRow(row: any[], style: string): any[] {
  return row.map(cell => {
      // If the cell is an object, merge the style with the existing properties
      if (typeof cell === 'object' && cell !== null) {
          return { ...cell, style: style };
      }
      // If the cell is a simple value, create a new object with text and style
      else {
          return { text: cell, style: style };
      }
  });
}



pdfCreator(promotions: any) {
  const transformedDataArray = this.transformDataForPdfMake2(promotions);
  this.promotionIndex = 0;
  const styles: any = this.reportStylesService.getStyles();


  const documentDefinition = {
      content: [...transformedDataArray],
      pageOrientation: 'landscape' as PageOrientation,
      pageSize: 'A4' as PageSize,

      styles: styles,
      border: [false,false,false,false],

      dontBreakRows: false,
      info: {
        title: `POS and press brief report ${this.promotionName}`,
      },
      footer: (currentPage: number, pageCount: number) => {
        return {

          stack: [
            {
              columns: [
                {
                  text: `${this.disclaimer}`,
                  style: 'footerDisclaimer',
                  fontSize:6
                },

                {
                  text: `Promoflo © 2024 OnTap Data Inc. \n
                  Created: ${this.dateService.getCurrentDateTime()} \n
                  Page ${currentPage} of ${pageCount}`,
                  style: 'copyrightFooter',
                  fontSize:6

                }
              ]
            }
            ]
        };
    }
  };
// Dynamically generate a filename based on specific data
  const filename = `Pos_Press_Brief_Report_${this.filtered_by_promotion}_${this.filtered_by}.pdf`;
  pdfMake.createPdf(documentDefinition).download(filename);
  this.loadingService.toggleLoading(false);
  this.dynamicColumns = [];
}

filtered_by: string = '';
filtered_by_promotion: string = '';
dataLength:number;
async getPromoData(e: any) {
  if (!e.groupSelected) {
    return;
  }

  await this.crudService.getData(`${environment.baseApiUrl}promotion-headers?promotion_period_id=${e.promotionSelected.value}&buying_group_id=${e.groupSelected.value}`).toPromise().then((result: any) => {
    const sortedHeaders = result.sort((a:any, b:any) => {
      const descriptionA = a.description || '';
      const descriptionB = b.description || '';
      return descriptionA.localeCompare(descriptionB, 'en', { numeric: true });
    });

    this.headers = sortedHeaders;

  this.disclaimer = e.disclaimer;
  this.filtered_by = e.filtered.buying_group_name;
  this.filtered_by_promotion = e.promotionNameSelected;

  // Fetch media plan data.
  let promoDataWithCoreRange:any;

  this.crudService.getData(`${this.mediaPlanningUrl}?promotion_period_id=${e.promotionSelected.value}&buying_group_id=${e.groupSelected.value}&edlp=1`).subscribe((result: any) => {
    this.mediaPlanData = result;

    const firstHeaderId = result[0].promotion_header_id;

    promoDataWithCoreRange = result.map((promoProduct: any) => {
      return {
        ...promoProduct//,
        //promotion_header_id: promoProduct.edlp == 1 ? 0 : promoProduct.promotion_header_id
      };
    });

    const firstEdlpRecord = promoDataWithCoreRange.find((record: any) => record.edlp === 1);

    if(firstEdlpRecord){
      const EdlpHeader = {
        id: firstEdlpRecord.promotion_header_id,
        description: firstEdlpRecord.promotion_name,
        buying_end: '2024-10-06',
        buying_group_id: 11,
        buying_start: '2024-09-23',
        created_at: '2024-08-16T03:16:10.000000Z',
        deleted_at: null,
        edlp: 1,
        group_id: 0,
        location_id: 0,
        promotion_end: firstEdlpRecord.promotion_end,
        promotion_name: '2025 TC08',
        promotion_period_id: firstEdlpRecord.promotion_period_id,
        promotion_start: firstEdlpRecord.promotion_start,
        promotion_type: 7,
        rebates: [],
        status: 1,
        status_type: 'open',
        updated_at: '2024-08-16T03:17:19.000000Z'
      }
      this.headers.push(EdlpHeader);
    }

    if (e.export == true) {

      this.crudService.getExportData(
        `${environment.baseApiUrl}export/media-planning?promotion_period_id=${e.promotionSelected.value}&buying_group_id=${e.groupSelected.value}`,
        {},
        'blob'
      ).subscribe(
        (response: Blob) => {
              // Create a Blob from the response
              const blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

              // Create a download link
              const downloadLink = document.createElement('a');
              const url = window.URL.createObjectURL(blob);
              downloadLink.href = url;
              downloadLink.download = 'Web_POS_Ticket_' + e.promotionNameSelected + '_' + this.filtered_by + '.xlsx';

              // Append the link, trigger the download, then remove the link
              document.body.appendChild(downloadLink);
              downloadLink.click();
              document.body.removeChild(downloadLink);
              window.URL.revokeObjectURL(url);
          },
          (error:any) => {
              console.error('Error:', error);
          }
      );
    }
    // Define any keys that should be excluded from being treated as dynamic columns.
    this.standardKeys = [
      'promotion_period_id', 'promotion_header_id', 'promotion_group_id', 'promotion_group_name',
      'promotion_name', 'promotion_start', 'promotion_end', 'buying_group_name', 'price', 'store_position_name', 'planning_category_name'
    ];

    // Update dynamic columns based on media plan data.
    this.dynamicColumns = []; // Reset or initialize dynamicColumns array
    if (promoDataWithCoreRange && promoDataWithCoreRange.length > 0) {
      Object.keys(promoDataWithCoreRange[0]).forEach(key => {
        if (!this.standardKeys.includes(key) && !this.dynamicColumns.includes(`media_plan_${key}`)) {
          this.dynamicColumns.push(`media_plan_${key}`);
        }
      });
    }

    // Group data by 'promotion_name'
    const groupedData = promoDataWithCoreRange.reduce((acc:any, item:any) => {
        // Use promotion_name as the key to group.
        acc[item.promotion_name] = acc[item.promotion_name] || [];
        acc[item.promotion_name].push(item);
        return acc;
    }, {});
    const finalData = this.groupProductService.groupByUniqueThirstyPOS(promoDataWithCoreRange, this.headers);

    this.dataLength = Object.keys(groupedData).length;
    console.log("gd:",finalData)
    this.pdfCreator(finalData);


  });
});
}


@Input() selectedFilter: string = '';
handleSelectedFilter(filter: string) {
  this.selectedFilter = filter;
  }
}
