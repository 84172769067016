import { Component, Input , EventEmitter, Output, ViewChild, OnInit, ChangeDetectorRef, Injectable } from '@angular/core';
import { DxTreeViewComponent, DxValidatorComponent } from 'devextreme-angular';
import { PromotionHeaderName } from 'src/app/models/promotion-name.model';
import { CrudService } from 'src/app/services/crud.service';
import { environment } from 'src/environments/environment';
import { DateService } from 'src/app/services/date.service';
import { LoadingService } from '../report-loading.service';
import { Subscription } from 'rxjs';
import validationEngine from 'devextreme/ui/validation_engine';
import notify from 'devextreme/ui/notify';
import { ConfigService } from 'src/app/services/config.service';
import { NotifyService } from 'src/app/services/notify.service';
@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.css']
})
export class FilterComponent implements OnInit {
  @ViewChild(DxTreeViewComponent, { static: false }) treeView:any;
  treeBoxValue: string[];
  @Input() name: string;
  @Input() reportComponent: string;
  @Output() selectedFilter: EventEmitter<string> = new EventEmitter();
  @Output() selectedFilter2: EventEmitter<string> = new EventEmitter();
  @Output() selectedBuyInGreenFilter: EventEmitter<string> = new EventEmitter();
  exportToExcel: boolean = false;
  rebate: boolean = false;
  excludePrice: boolean = false;
  @Output() selectedHeader: EventEmitter<any> = new EventEmitter();
  promotionNames: PromotionHeaderName[];
  promotionSelectableNames: PromotionHeaderName[];
  buyingGroups: BuyingGroup[];
  suppliers: Suppliers[];
  suppliersView: Suppliers[];
  reportDescription:string;
  private baseUrl: string = environment.baseApiUrl;
  promotionNameSelected: any = null;
  promotionSelected: any = null;
  groupSelected: any = null;
  supplierSelected: any = null;
  coreRangeSelected: any = null;
  buyInGreenDateSelected:any = null;
  coreRangeHeaders:any;


  storedWeeksSelected: any = 4;
  selectedSuppliers: any = null;
  storedPromotionIDs: any = null;
  selectedDate: Date = new Date();
  loading: boolean = false;
  selectedRange: any;
  validationGroup = 'validationGroup';
  private loadingSubscription: Subscription;
  isOpen:boolean = false;
  @Injectable() disclaimer:string = '';
  maxLength: number = 400;
  filterBy: { [key: string]: string } = {
    "Supplier": "supplier_name",
    "Store Location": "store_position_name",
    "Description": "description",
    "Carton Quantity": "carton_quantity"
  };
  disclaimerNames: { [key: string]: string } = {
    "Core Range": "CoreRangeDisclaimer",
    "Promotion Advice": "PromoAdvDisclaimer",
    "ALM Advice": "ALMAdvDisclaimer",
    "Pos & Press Brief Report": "PosDisclaimer",
    "Promotion Confirmation": "PromoConDisclaimer",
    "Buy in the Green": "BIGDisclaimer",
    "Promotion Summary": "PromoSumDisclaimer",

  };

  filterArray: { id: string, text: string }[] = [];

  constructor(private crudService: CrudService, private dateService: DateService, private cdr: ChangeDetectorRef, private loadingService: LoadingService, private configService: ConfigService, private notifyService:NotifyService)
  {
    this.loadingSubscription = this.loadingService.isLoading$.subscribe(isLoading => {
      this.loading = isLoading;
    });

    
    
  }

 
ngOnInit(): void {
  this.disclaimer = "";
  this.getPromotionNames();
    this.getBuyingGroups();
    if(this.name == 'Core Range'){
  
      
      this.getConfigString(this.disclaimerNames[this.name]);
      
      }
      if(this.name == 'Promotion Summary'){
      this.getConfigString(this.disclaimerNames[this.name]);
      
      }
      if(this.name == 'Buy in the Green'){
      this.getConfigString(this.disclaimerNames[this.name]);
      
      }
      if(this.name == 'Promotion Confirmation'){
      this.getConfigString(this.disclaimerNames[this.name]);
      
      }
      if(this.name == 'Pos & Press Brief Report'){
      this.getConfigString(this.disclaimerNames[this.name]);
      
      }
      if(this.name == 'ALM Advice'){
        this.getConfigString(this.disclaimerNames[this.name]);
      
      }
      if(this.name == 'Promotion Advice'){
        this.getConfigString(this.disclaimerNames[this.name]);
      }

   
}

  getViewPdfAction() {
    



  if(this.name === 'Core Range' || this.name === 'Rebate & Focus Export'){
      this.useCoreRangeStoredData();
  }
  else if(this.name === 'Buy in the Green'){
      this.useBuyInGreenStoredData();
  }
  else if(this.name === 'Promotion Confirmation'){
      this.useSupplierStoredData();
  }
  else if(this.name !== 'Promotion Confirmation' && this.name !== 'Buy in the Green' && this.name !== 'Core Range' 
    && this.name !== 'Rebate & Focus Export') {
        this.useStoredData();
        
    }
}
  async getPromotionNames() {
    try {
        // Fetch data from the API endpoint
        const response = await this.crudService.getData(this.baseUrl + 'promotion-headers').toPromise();

        // Assert the response data type to an array of PromotionHeaderName
        const data = response as PromotionHeaderName[];

        // Create a new Set to store unique promotion names
        const uniquePromotionNames = new Set();
        // Filter the data to include only unique items based on promotion_name
        const uniqueData = data.filter(item => {
            if (!uniquePromotionNames.has(item.promotion_name)) {
                uniquePromotionNames.add(item.promotion_name);
                return true;
            }
            return false;
        });

        // Store the unique promotion names in this.promotionNames
        this.promotionNames = uniqueData;
        localStorage.setItem('promotion-names', JSON.stringify(this.promotionNames));

    } catch (error) {
        console.error('Failed to fetch promotion names:', error);
    }
}


  async getBuyingGroups() {
    const result = await this.crudService.getData(this.baseUrl + 'buying-groups').toPromise();
    this.buyingGroups = result;

    localStorage.setItem('buying-groups', JSON.stringify(this.buyingGroups));
  }
  async getCoreRangeHeaders() {
    const preFetchCoreRangeHeaders = await this.crudService.getData(this.baseUrl + 'core-range-headers').toPromise();

    const data = preFetchCoreRangeHeaders.data;

    // Assuming this.groupSelected.value holds the buying_group_id you want to filter by
    const buyingGroupId = this.groupSelected.value;

    // Filter the data array by the buying_group_id
    const filteredData = data.filter((item:any) => item.buying_group_id === buyingGroupId);
    // Now, filteredData holds only the entries that match the given buying_group_id
    this.coreRangeHeaders = filteredData;

}

  async getSuppliers() { 
    try {
     
        const preFetchSuppliers = await this.crudService.getData(this.baseUrl + `promotion-details?promotion_period_id=${this.promotionSelected.value}&buying_group_id=${this.groupSelected.value}&edlp`).toPromise();
        if (!preFetchSuppliers || !Array.isArray(preFetchSuppliers.data)) {
            console.error('Expected an array of data from the server, but got:', preFetchSuppliers);
            return; // Exit if the data is not as expected
        }
        const data = preFetchSuppliers.data; // This is assured to be an array
        if(data == "No records returned"){
          notify("No suppliers found", "warning", 3000);
  
        }
        const uniqueSuppliersSet = new Set();
        const uniqueSupplierCodesSet = new Set();
        // Filter to get unique items based on supplier_name
        const uniqueData = data.filter((item:any) => {
            if (!uniqueSupplierCodesSet.has(item.supplier_code)) {
                uniqueSuppliersSet.add(item.supplier_name);
                uniqueSupplierCodesSet.add(item.supplier_code);
                return true; // Keep this item in the new array
            }
            return false; // Skip this item
        });
        this.suppliers = uniqueData.sort((a:any, b:any) => {
          const nameA = a.supplier_name.toUpperCase(); // Ignore case
          const nameB = b.supplier_name.toUpperCase(); // Ignore case
          if (nameA < nameB) {
            return -1; // nameA comes first
          }
          if (nameA > nameB) {
            return 1; // nameB comes first
          }
          return 0; // names are equal
        });
        
        this.suppliersView = [...this.suppliers];
        if(data == "No records returned"){
          return}
        this.suppliersView.unshift({
          supplier_name: "All Suppliers",
          id: 0,
          group_id: 0,
          location_id: 0,
          supplier_code: 'all_suppliers'
        });
      

  
        
    } catch (error) {
        console.error('Error fetching suppliers:', error);
    }
}
  onValueChanged(e:any){
    this.exportToExcel = !this.exportToExcel;
  }
  onRebateCheck(e:any){
    this.rebate = !this.rebate;
  }
  onPriceExclude(e:any){
    this.excludePrice = e.value;
  }
  useStoredData() {
  
    const filter = this.buyingGroups.find(record => record.id === this.groupSelected?.value) || { defaultFilter: true };
    const eventData = {
      "promotionSelected": this.promotionSelected,
      "promotionNameSelected": this.promotionNameSelected,
      "groupSelected": this.groupSelected,
      "groupSelectedName": this.groupSelectedName,
      "storedPromotionIDs": this.storedPromotionIDs,
      "filtered": filter,
      "export": this.exportToExcel,
      "disclaimer": this.disclaimer
    };

    if (this.promotionSelected && this.groupSelected) {
      this.selectedHeader.emit(eventData);
    }
  }
  async useSupplierStoredData() {
    if(!this.selectedSuppliers)
      {
       
      }
    const buyingGroupFilter = this.buyingGroups.find(record => record.id === this.groupSelected?.value) || {
        defaultFilter: true
    };
    let suppliers: any;
   
    // Adjusted logic for filteredAnotherData
    if(this.selectedSuppliers.value == "all_suppliers"){
      await this.getSuppliers();
      suppliers = this.suppliers;
    }
    else{
      suppliers = this.selectedSuppliers;
    }
    //const suppliers = this.selectedSuppliers === "All Suppliers" ? this.suppliers : this.selectedSuppliers;
   
    const eventData = {
      "promotionSelected": this.promotionSelected,
      "promotionNameSelected": this.promotionNameSelected,
      "groupSelected": this.groupSelected,
      "groupSelectedName": this.groupSelectedName,
      "suppliers": suppliers,
      "buyingGroupFiltered": buyingGroupFilter,
      "excludePrice": this.excludePrice,
      "disclaimer": this.disclaimer
    };
  
    if (this.promotionSelected && this.groupSelected) {
      this.selectedHeader.emit(eventData);
    }
}

useCoreRangeStoredData() {
  const buyingGroupFilter = this.buyingGroups.find(record => record.id === this.groupSelected?.value) || {
      defaultFilter: true
  };

  const eventData = {
    "coreRange": this.coreRangeSelected,
    "buyingGroup": this.groupSelected,
    "buyingGroupFiltered": buyingGroupFilter,
    "start_date": this.coreRangeSelected.value.start_date,
    "end_date": this.coreRangeSelected.value.end_date,
    "rebate": this.rebate,
    "disclaimer": this.disclaimer,
 
  };
  if (this.coreRangeSelected && this.groupSelected) {
    this.selectedHeader.emit(eventData);
  }
}
useBuyInGreenStoredData(){
  const buyingGroupFilter = this.buyingGroups.find(record => record.id === this.groupSelected?.value) || {
      defaultFilter: true
  };
  const eventData = {
    "buyInGreenDateSelected": this.buyInGreenDateSelected,
    "weeksCount": this.storedWeeksSelected,
    "buyingGroupFiltered": buyingGroupFilter,
    "export": this.exportToExcel,
    "disclaimer": this.disclaimer
  };
  if (this.promotionSelected && this.groupSelected) {
    this.selectedHeader.emit(eventData);
  }
}

storePromotionName(event: any) {
  console.log("promo name:", event)
    this.promotionSelected = event || {}; // Provide a default empty object if event is not available
    this.promotionNameSelected = event.component.option("text");
    
  }
  validateAndSubmit() {
    const result = validationEngine.validateGroup(this.validationGroup);
    if (result.isValid) {
      this.getViewPdfAction();
      this.loadingService.toggleLoading(true);
    } else {
      notify("Please correct the errors.", "error", 3000);
    }
  }
  groupSelectedName:any = null;
storeGroupData(event: any) {
  console.log("promo group:", event)
    this.groupSelected = event || {}; // Provide a default empty object if event is not available
    this.groupSelectedName = event.component.option("text");
    const filterKey = event ? this.filterBy[this.groupSelected.value] : 'defaultFilter';
  
    this.selectedFilter.emit(filterKey);
    if(this.name == 'Core Range' || this.name == 'Rebate & Focus Export'){
      this.getCoreRangeHeaders();
    }
  }
  storeBuyInGreenDate(event: any) {
    this.storePromotionName(event);
    const newValue = event.value;

    const formattedDate = this.dateService.dateFormat(newValue);
    this.buyInGreenDateSelected = formattedDate; // Provide a default empty object if event is not available
  }
  storeBuyInGreenWeeksSelected(event: any) {
    const newValue = event.value;

    this.storedWeeksSelected = newValue;
  }
  
  storeSupplierFilterData(event: any) {
    console.log("supplier id:", event)
    this.selectedSuppliers = event || {}; // Provide a default empty object if event is not available
    const filterKey2 = event ? this.filterBy[this.selectedSuppliers.value] : 'defaultFilter';
  

    this.selectedFilter.emit(filterKey2);
  }
  storeCoreRangeData(event: any) {
    this.coreRangeSelected = event || {}; // Provide a default empty object if event is not available
  
  }

  toggleDropdown(): void {
    
    
    this.isOpen = !this.isOpen;
  }
  async getConfigString(config: string) {
    try {
        // Fetching configuration data
        const response = await this.configService.GetConfigByKey(config);
  
        // Check if the response array is not empty and has the property
        if (response.length > 0 && response[0].config_string) {
            this.disclaimer =  response[0].config_string;
         
        } else {
            console.log('No config found or missing config_string');
            this.disclaimer = '';
        }
    } catch (error) {
        console.error('Failed to get config string:', error);
    }
  }
  submitDisclaimer(){
    const values: any = {};
  console.log('Disclaimer: ', this.disclaimer);
  if(this.disclaimer == null || this.disclaimer == ""){
  this.configService.insertConfig(values, this.disclaimerNames[this.name], this.disclaimer).add(() => {
    this.notifyService.tellUser({success: true, data: 'Configuration created successfully'});
  });
  }
  else{
    this.configService.updateConfig(this.disclaimerNames[this.name], this.disclaimer).add(() => {
      this.notifyService.tellUser({success: true, data: 'Configuration updated successfully'});
    });
  }
  this.toggleDropdown();
  }
  
 
  ngOnDestroy(): void {
    this.loadingSubscription.unsubscribe(); // Prevent memory leaks
  }
}

export interface NoSelection {
  value: null;
}

export class BuyingGroup
{
    id: number;
    group_id: number;
    location_id: number;
    buying_group_name: string
    buying_group_code: string;

}
export class Suppliers
{
    id: number;
    group_id: number;
    location_id: number;
    supplier_code: string
    supplier_name: string;

}
