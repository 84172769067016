import { Component, ViewChild } from '@angular/core';
import { ProductCategories } from 'src/app/models/product-categories.model';
import { CrudService } from 'src/app/services/crud.service';
import CustomStore from 'devextreme/data/custom_store';
import { environment } from 'src/environments/environment';
import { HorizontalMenuService } from 'src/app/services/horizontalmenu.service';
import { ConfirmationService } from 'src/app/services/confirmation.service';
import { DxDataGridComponent } from 'devextreme-angular';

@Component({
    selector: 'app-product-categories',
    templateUrl: './product-categories.component.html',
    styleUrls: ['./product-categories.component.css'],
    standalone: false
})
export class ProductCategoriesComponent {
  private productCategories: ProductCategories[];
  private apiUrl: string = environment.baseApiUrl;
  dataSource: any;
  editProdCategories: boolean = false;

  @ViewChild("productCategoryGrid", { static: false }) public dataGrid: DxDataGridComponent;

  constructor(private crudService: CrudService,
      private horizontalMenuService: HorizontalMenuService, private confirmationService:ConfirmationService) 
  {

    this.dataSource = new CustomStore<any>({
      key: 'id',
      load: async () => {
        return await new Promise((resolve) => {
          this.crudService.getData(`${this.apiUrl}product-categories`).subscribe((result: any) => {
            localStorage.setItem('product-categories', JSON.stringify(result));
            resolve({data: result, totalCount: result.length});
          })
        })
    },
      insert: async (values) => {
        const userDetails = localStorage.getItem('userDetails');
        const parsedDetails = JSON.parse(userDetails || '{}');
        values.group_id = parsedDetails.data.group_id;
        values.location_id = parsedDetails.data.location_id;
        console.log(values);
        return await new Promise(async (resolve) => {
          const result: any = await this.crudService.insertData(`${this.apiUrl}product-categories`, values)
          .subscribe((result: any) => {
            resolve(result);
          });
        });
      },
      update: async (key, values) => {
        return await new Promise(async (resolve) => {
          const result: any = await this.crudService.updateData(`${this.apiUrl}product-categories`, key, values)
          .subscribe((result: any) => {
            resolve(result);
          });
        });
      },
      remove: async (key) => {
        return await new Promise(async (resolve) => {
          const result: any = await this.crudService.deleteData(`${this.apiUrl}product-categories`, key)
          .subscribe((result: any) => {
            resolve(result);
          });
        });
      }
    });
    this.horizontalMenuService.editProductCategories$.subscribe((value: boolean) => {
      this.editProdCategories = value;
    });
  }

  onHiding(event: any) {
    console.log("test", event);
    this.confirmationService.checkUnsaved(event, this.dataGrid, (value: boolean) => {
        this.horizontalMenuService.seteditProductCategories(value);
        console.log("Popup visibility should be set to:", value);
    });
    this.horizontalMenuService.seteditProductCategories(false);
}


}
