import { Component, Input } from '@angular/core';
import { Observable, map } from 'rxjs';
import { CrudService } from 'src/app/services/crud.service';
import { environment } from 'src/environments/environment';
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { PageOrientation } from 'pdfmake/interfaces';
import { PageSize } from 'pdfmake/interfaces';
import { MixMatchReport } from 'src/app/models/mix-match-report.model';
import { Product } from 'src/app/models/product.model';
import { ReportStylesService } from 'src/app/services/report-styles.service';
import { DateService } from 'src/app/services/date.service';
import { Base64Images } from 'src/app/services/base64-images.service';
import { UserDetails } from 'src/app/models/user-details';
import { DateFormatService } from 'src/app/services/date-format.service';
import { CostServiceService } from 'src/app/services/cost-service.service';
import { LoadingService } from '../report-loading.service';

@Component({
    selector: 'app-promotion-mix-match',
    templateUrl: './promotion-mix-match.component.html',
    styleUrls: ['./promotion-mix-match.component.css'],
    standalone: false
})
export class PromotionMixMatchComponent {
  @Input() name: string;
  @Input() reportComponent: string;
  private apiUrl: string = environment.baseApiUrl;
  data$: any;
  private promotionPeriodName: string;
  private buyingGroupName: string;
  private disclaimer: string;
  private userDetails: UserDetails;
  private promotionStartDate: string;
  private promotionEndDate: string;
  private promotion_description: string;

  constructor(private crudService: CrudService,
    private reportStylesService: ReportStylesService,
    private dateService: DateService,
    private base64Image: Base64Images,
    private dateFormatService: DateFormatService,
    private costService: CostServiceService,
    private loadingService: LoadingService) { 
    const userDetailsData = localStorage.getItem("userDetails");
    if (userDetailsData) {
      this.userDetails = JSON.parse(userDetailsData || '{}');
    } else {
        this.userDetails = {} as UserDetails;
    }
  }

  async getPromoData(e: any) {
    if (!e.groupSelected) {
      return;
    }
    console.log('selection', e);
    this.promotionPeriodName = e.promotionNameSelected;
    this.buyingGroupName = e.groupSelectedName;
    this.disclaimer = e.disclaimer;
    return this.crudService.newGetData(`${this.apiUrl}mix-match-report?promotion_period_id=${e.promotionSelected.value}&buying_group_id=${e.groupSelected.value}`)
      .subscribe((data) => {
        this.data$ = data.data;
        this.loadingService.toggleLoading(false);
        return this.pdfCreator(data.data);
      });
  }

  @Input() selectedFilter: string = '';
  handleSelectedFilter(filter: string) {
    this.selectedFilter = filter;
  }

  pdfCreator(mixMatchData: MixMatchReport[]) {

    const transformedDataArray = this.transformDataForPdfMake(mixMatchData);
    const styles: any = this.reportStylesService.getStyles();
  
    const documentDefinition = {
        content: [...transformedDataArray],
        pageOrientation: 'portrait' as PageOrientation,
        pageSize: 'A4' as PageSize,
        styles: styles,
        border: [false,false,false,false],
        info: {
          title: `Promotion Mix Match Report - ${this.promotionPeriodName} - ${this.buyingGroupName}`,
        },
        footer: (currentPage: number, pageCount: number) => {
          return {
  
            stack: [
              {
                columns: [
                  {
                    text: `${this.disclaimer} ~ Represents averaged cost & percentage data. \n`,
                    style: 'footerDisclaimer',
                    fontSize:6
                  },
                 
                  {
                    text: `Promoflo © 2024 OnTap Data Inc. \n
                    Created: ${this.dateService.getCurrentDateTime()} \n
                    Page ${currentPage} of ${pageCount}`,
                    style: 'copyrightFooter',
                    fontSize:6
  
                  }
                ]
              }
              ]
          };
      }
    };
    pdfMake.createPdf(documentDefinition).download(`Promotion Mix Match Report - ${this.promotionPeriodName} - ${this.buyingGroupName}.pdf`);
    this.loadingService.toggleLoading(false);
  }

  transformDataForPdfMake(mixMatchData: MixMatchReport[]): any[] {
    const headers = [
      '', 'Code', 'Product Description', 'Cost',
      'Sell', 'GP%', 'Profit', ''
    ];
    const styledHeaders = headers.map(header => ({ text: header, style: 'tableHeader' }));
  
    const body: any[] = [];
  
    mixMatchData.forEach(header => {
      this.promotionStartDate = this.dateFormatService.changeDateFormat(header.promotion_start);
      this.promotionEndDate = this.dateFormatService.changeDateFormat(header.promotion_end);
      const avgInvPrice = 
        ((Number(header.avg_cost_inc_wet_only) - Number(header.avg_allowance_total)) / 
        Number(header.avg_packs_per_carton) * Number(header.avg_purchase_quantity)) * header.total_selection_levels;
      const avgProfit = Number(header.avg_sell_price) - avgInvPrice;
      const product: Product = {
        mix_match_header_id: header.mix_match_header_id,
        cost_ex_gst: header.total_level_calc_cost,
        packs_per_carton: header.avg_packs_per_carton,
        purchase_quantity: header.avg_purchase_quantity,
        sell_price: header.avg_sell_price,
        allowance_total: 0,
        rebate: header.avg_rebate,
        cost_inc_wet_only: header.avg_cost_inc_wet_only,
        has_wet: 0
      } as Product;
      // Add mix match header row
      if(this.promotion_description !== header.promotion_description){
        body.push([{ colSpan: 8, text: header.promotion_description, style: 'bodyStyleLargeFont' }, {}, {}, {}, {}, {}, {}, {}] );
      }
      this.promotion_description = header.promotion_description;
      body.push([{ colSpan: 3, text: header.mix_match_header_name, style: 'mixMatchHeaderStyle' }, 
          {},
          {},
          { colSpan: 1, text: `~$${Number(header.total_level_calc_cost).toFixed(2)}`, style: 'mixMatchAvgStyle' },
          { colSpan: 1, text: `$${Number(header.avg_sell_price).toFixed(2)}`, style: 'mixMatchAvgStyle' },
          { colSpan: 1, text: `~${this.costService.calculateGpPercent(product)}`, style: 'mixMatchAvgStyle' },
          { colSpan: 1, text: `~$${this,this.costService.calculateGpAmount(product).toFixed(2)}`, style: 'mixMatchAvgStyle' }, 
          { colSpan: 1, text: ` `, style: 'mixMatchAvgStyle' }]);
  
      header.selection_levels.forEach(level => {
        body.push([{ colSpan: 3, text: `${level.selection_level_description} - Qty: ${level.purchase_quantity}`, style: 'supplierStyle' }, 
          {}, 
          {}, 
          { colSpan: 1, text: `~$${Number(level.level_calc_cost).toFixed(2)}`, style: 'bodyStyleBold' }, 
          {}, {}, {}, {}]);
        level.products.forEach(product => {
          const invPrice = ((Number(product.cost_inc_wet_only) - product.allowance_total) / Number(product.packs_per_carton));
          product.purchase_quantity = level.purchase_quantity;
          body.push([
            {},
            {text: product.product_code, style: 'bodyStyle'},
            {text: product.product_name, style: 'bodyStyle'},
            {text: `$${Number(invPrice).toFixed(2)}`, style: 'bodyStyle'},
            {},
            {},
            {},
            {}
          ]);
        });
      });
    });
    const subheader = {
      stack: [
        {
          columns: [
            {
              text: `${this.name}`,
              style: 'header',
              alignment: 'left',
            },
            {
                image: this.base64Image.getBase64Image(this.userDetails.data.location_token),
                width: 150,
                alignment: 'right',
                margin: [0, 0, 0, 0]
            }
          ]
        }
      ]
    };

    const secondHeader = {
      stack: [
        {
          columns: [
            {
              text: `${this.promotionPeriodName} - ${this.buyingGroupName}`,

              style: 'subheader',
              alignment: 'left',
              margin: [10, 0, 0, 0]  // Adjust margin as needed for the text
            },
            {
              text: `Promotion Dates: ${this.promotionStartDate} - ${this.promotionEndDate}`,
              style: 'subheader',
              alignment: 'right',
              margin: [0, 0, 10, 0]  // Adjust margin as needed for the text
            }
          ]
        }
      ]
    };

    return [
      subheader,
      secondHeader,
      {
          table: {
              headerRows: 1,
              widths: [30, 30, '*', 40, 40, 40, 40, 30 ],
              body: [styledHeaders, ...body],
          },
          // pageBreak: 'after',
          layout: {
            hLineWidth: () => 0,
            vLineWidth: () => 0,
            hLineColor: () => 'white',
            vLineColor: () => 'white',
            fillColor: function (rowIndex: number, node:any, columnIndex:any) {
              return (rowIndex % 2 === 0) ? '#d9f0f1' : null;
            },

          }
      }
    ];
  }

  styleRow(row: any[], style: string): any[] {
    return row.map(cell => {
        // If the cell is an object, merge the style with the existing properties
        if (typeof cell === 'object' && cell !== null) {
            return { ...cell, style: style };
        }
        // If the cell is a simple value, create a new object with text and style
        else {
            return { text: cell, style: style };
        }
    });
  }

}
