<div class="nav_id"><p><u>slotting</u></p></div>
<app-custom-loader *ngIf="!gridBuilt"></app-custom-loader>
<div class="gridContainer" [ngClass]="{ 'hidden': !gridBuilt, 'visible': gridBuilt }">
  <dx-data-grid
    #gridContainer
    [dataSource]="filteredData"
    [remoteOperations]="false"
    [keyExpr]="['product_group_code', 'buying_group_id', 'promo_group_id', 'supplier_code']"
    [showBorders]="true"
    [rowAlternationEnabled]="true"
    [columns]="columns"
    [height]="'95%'"
    [allowColumnReordering]="false"
    [allowColumnResizing]="true"
    [showColumnLines]="showVerticalLines"
    (onCellHoverChanged)="eventData($event)"
    (onContextMenuPreparing)="contextMenuItems($event)"
    (onEditorPreparing)="styleLookup($event)"
    (onCellPrepared)="onCellPrepared($event)"
    (onInitialized)="setTimeouts($event)"
    (onExporting)="onExporting($event)"
  >
  <dxo-filter-row
  [visible]="showFilterRow"
  [applyFilter]="true">
</dxo-filter-row>
<dxo-filter-panel [visible]="true"></dxo-filter-panel>
<dxo-export [enabled]="true" [allowExportSelectedData]="false"></dxo-export>
<dxo-search-panel
  [visible]="true"
  [width]="240"
  placeholder="Search...">
</dxo-search-panel>
    <dxo-column-fixing [enabled]="true"></dxo-column-fixing>
    <dxi-column cssClass="text_boxs" dataField="product_group_name" dataType="string" groupCellTemplate="productGroupColumn" caption="Category"
      [fixed]="true" width="180" [allowFiltering]="true" [allowEditing]="false">
      <div *dxTemplate="let data of 'productGroupColumn'">
          <span>{{data.displayValue}}</span>
        </div>
    </dxi-column>

    <dxi-column cssClass="text_boxs" dataField="buying_group_name" dataType="string" groupCellTemplate="buyingGroupColumn" caption="Buying Group"
      [fixed]="true" width="200" [allowFiltering]="true"  [allowEditing]="false">
      <div *dxTemplate="let data of 'buyingGroupColumn'">
        <span>{{data.displayValue}}</span>
      </div>
    </dxi-column>

    <dxi-column cssClass="text_boxs" dataField="promotion_group_name" dataType="string" groupCellTemplate="promotionGroupColumn" caption="Promotion Group"
      [fixed]="true" minWidth="180"[allowFiltering]="true"  [allowEditing]="false">
      <div *dxTemplate="let data of 'promotionGroupColumn'">
        <span>{{data.displayValue}}</span>
      </div>
    </dxi-column>

    <dxi-column cssClass="text_boxs"dataField="supplier_name" dataType="string" groupCellTemplate="supplierColumn" caption="Supplier"
      [fixed]="true" width="180" [allowFiltering]="true"  [allowEditing]="false">
      <div *dxTemplate="let data of 'supplierColumn'">
        <span>{{data.displayValue}}</span>
      </div>
    </dxi-column>

    <ng-container *ngFor="let header of columns">
      <dxi-column *ngIf="header.isBand"
          [caption]="header.caption"
          [name]="header.name"
          [allowHeaderFiltering]="false"
          [allowReordering]="true"
          [headerCellTemplate]="header.headerTemplate"
          [allowFixing]="false"
          [dataType]="header.dataType"
          [allowGrouping]="false"
          [allowReordering]="false"
          [allowFiltering]="false"
          [cssClass]="header.cssClass"
      >
        <ng-container *ngFor="let column of columns">
          <dxi-column *ngIf="column.ownerBand === header.caption"
            [headerCellTemplate]="column.headerTemplate"
            [caption]="column.caption"
            [minWidth]="column.minWidth"
            [allowEditing]="column.allowAdding"
            [dataField]="column.dataField"
            [dataType]="column.dataType"
            [name]="column.name"
            [lookup]="column.lookup"
            [cellTemplate]="column.cellTemplate"
            [alignment]="column.alignment"
            [allowHeaderFiltering]="false"
            [allowSorting]="false"
            [cssClass]="column.cssClass"
            [setCellValue]="setCellValue"
            [allowReordering]="column.allowReordering"
            [minWidth]="column.minWidth"
            [allowGrouping]="false"
            [allowFiltering]="false"
            >
          </dxi-column>
        </ng-container>
      </dxi-column>
  </ng-container>
  <div *dxTemplate="let data of 'promoTemplate'">
    <div class="promo-caption">
      {{data.column.caption}}
      <div class="is-active" *ngIf="hasMatchingPromotion(data.column.caption)"></div>
    </div>
  </div>
    <div *dxTemplate="let data of 'headerTemplate'" style="padding: 0px; height: 100%;">
      <div class="container" >
        <div class="box-container" >
          <div class="box red"  *ngIf="data.column.caption" [style.visibility]="hasEvent(data.column.caption)"></div>
          <div class="box blue"*ngIf="data.column.caption" [style.visibility]="hasCustomEvent(data.column.caption)"></div>
          <div class="box green"></div>
        </div>
        <div class="content">
          {{data.column.dataField}}
        </div>
        <div *ngFor="let startDate of startDates" class="week-date">
          <div *ngIf="data.column.caption == startDate.week_id">{{startDate.start_date}}</div>
        </div>
      </div>
    </div>
    <div *dxTemplate="let data of 'cellTemplate'">
      <div class="circle" *ngIf="data.value" [style.background-color]="getSlotTypeColour(data.value)">
        <p class="circle-inner">{{data.value}}</p>
      </div>
    </div>
    <dxo-master-detail
      [enabled]="true"
      template="detail"
    >
    </dxo-master-detail>
    <div *dxTemplate="let promotionGroupData of 'detail'">
      <div class="master-detail-caption">
        {{promotionGroupData.data.product_group_name}}
      </div>
      <app-detail-view [key]="promotionGroupData.data.promo_group_id" [buyingGroup]="promotionGroupData.data.buying_group_id"></app-detail-view>
    </div>

    <!-- <dxo-summary>
      <dxi-group-item column="groupId" summaryType="count"></dxi-group-item>
    </dxo-summary> -->
    <dxo-column-fixing [enabled]="true"></dxo-column-fixing>
    <dxo-paging [pageSize]="10"></dxo-paging>
    <dxo-group-panel [visible]="true"></dxo-group-panel>
    <dxo-grouping #expand [autoExpandAll]="true"></dxo-grouping>
    <dxo-header-filter [visible]="true"></dxo-header-filter>
    <dxo-scrolling mode="virtual"></dxo-scrolling>
    <dxo-load-panel [enabled]="false"></dxo-load-panel>
    <dxo-editing
      mode="batch"
      refreshMode="full"
      [allowUpdating]="true"
      [allowDeleting]="false"
      [allowAdding]="false"
    >
    </dxo-editing>
    <dxo-toolbar>
    <!-- search Toolbar Item -->
    <dxi-item  location="after">
      <div *dxTemplate>
    <dx-button class="search-toggle"
    icon="search"
    [class.active]="showFilterRow"

    (onClick)="toggleFilterRow()">
    </dx-button>
    </div>
    </dxi-item>
    <dxi-item location="after">
      <div *dxTemplate>
        <dx-button icon="detailslayout" (onClick)="enableVerticalLines()" hint="Enable vertical grid lines">
        </dx-button>
      </div>
    </dxi-item>
      <dxi-item name="groupPanel"></dxi-item>
      <!-- <dxi-item name="saveButton"></dxi-item>
      <dxi-item name="revertButton"></dxi-item> -->
      <dxi-item
        widget="dxButton"
        [options]="buttonOptions"
        location="after" hint="Select Date Range">
    </dxi-item>
    <dxi-item location="after">
      <div *dxTemplate>
        <dx-button icon="copy" (onClick)="openCloneSelection()" hint="Clone Slotting Data">
        </dx-button>
      </div>
    </dxi-item>
    <dxi-item name="exportButton"></dxi-item>
      <!-- 3-way-toggle -->
      <dxi-item location="after">
        <div *dxTemplate>
        <dx-drop-down-button
            [(items)]="toggleStateItems"
            displayExpr="name"
            keyExpr="id"
            width="120px"
            [text]="dropdownButtonText"
            [useSelectMode]="true"
            [(selectedItem)]="currentToggleState"
            (onSelectionChanged)="onDropdownSelectionChanged($event)"
           >
        </dx-drop-down-button>
      </div>
    </dxi-item>
    </dxo-toolbar>
    <dxo-state-storing
      [enabled]="true"
      type="custom"
      [storageKey]="storageKey"
      [customLoad]="loadState"
      [customSave]="saveState"
    ></dxo-state-storing>
  </dx-data-grid>
  <dx-popup
  [(visible)]="weekSelector"
  [hideOnOutsideClick]="true"
  [showTitle]="true"
  title="Select week range"
  height="auto"
  width="auto"
  [showCloseButton]="true">
    <app-week-range-selector (weekRangeValues)="reloadGrid($event)"></app-week-range-selector>
</dx-popup>
<dx-popup
  [(visible)]="cloneSlottingData"
  [hideOnOutsideClick]="true"
  [showTitle]="true"
  title="Clone Slotting Data"
  height="350px"
  width="650px"
  [showCloseButton]="true">
    <app-clone-slotting-data (clonePromotionsValues)="closeCloneSelection()"></app-clone-slotting-data>
</dx-popup>
<dx-popup
  [(visible)]="importPromotionData"
  [hideOnOutsideClick]="true"
  [showTitle]="true"
  title="Import Promotion"
  height="650px"
  width="80%"
  [showCloseButton]="true">
    <app-import-promotion [promotion_name]="promotion_name" [promotion_id]="promotion_id" (importPromotionValues)="closeImportSelection()"></app-import-promotion>
</dx-popup>
<dx-tooltip
  [target]="tooltipTarget">
  <div *dxTemplate="let data of 'content'">
    <div class="tooltipContent" *ngFor="let item of tooltipData">
      <div><b>Date:</b> {{formatDate(item.date)}}</div>
      <div><b>Event:</b> {{item.event_name}}</div>
      <div><b>State:</b> {{item.region}}</div>
      <div><hr></div>
    </div>
  </div>
</dx-tooltip>
<dx-tooltip class="hoverToolTip" #toolTipHover
  [target]="tooltipHoverTarget">
    <div class="tooltipHoverContent">
      <div>{{toolTipText}}</div>
  </div>
</dx-tooltip>
