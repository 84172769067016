import { Component } from '@angular/core';
import { ConfigService } from 'src/app/services/config.service';
import { NotifyService } from 'src/app/services/notify.service';

@Component({
    selector: 'app-configuration',
    templateUrl: './configuration.component.html',
    styleUrls: ['./configuration.component.css'],
    standalone: false
})
export class ConfigurationComponent {
  disclaimer: string = '';
  hasData: boolean = false;
  loadingVisible: boolean = false;

  constructor(private configService: ConfigService,
              private notifyService: NotifyService) {
    this.getConfiguration();
  }

  getConfiguration() {
    this.loadingVisible = true;
    this.configService.GetConfigByKey('ReportDisclaimer').then((data) => {
      if (data.length > 0) {
        this.disclaimer = data[0].config_string;
        this.hasData = true;
        this.loadingVisible = false;
      }
      else{
        this.loadingVisible = false;
      }
    });
  }

  saveConfiguration() {
    const values: any = {};
    console.log('Disclaimer: ', this.disclaimer);
    this.configService.insertConfig(values, 'ReportDisclaimer', this.disclaimer).add(() => {
      this.notifyService.tellUser({success: true, data: 'Configuration created successfully'});
    });
  }

  updateConfiguration() {
    this.configService.updateConfig('ReportDisclaimer', this.disclaimer).add(() => {
      this.notifyService.tellUser({success: true, data: 'Configuration updated successfully'});
    });
  }

  setDisclaimer(e: any) {
    this.disclaimer = e.value;
    console.log('Disclaimer: ', e);
    if(e.value !== null && e.previousValue !== "" && this.hasData) {
      this.updateConfiguration();
    } else if(e.event !== undefined){
      this.saveConfiguration();
    }
  }
}
