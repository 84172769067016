import { Component } from '@angular/core';

@Component({
    selector: 'app-custom-loader',
    templateUrl: './custom-loader.component.html',
    styleUrls: ['./custom-loader.component.css'],
    standalone: false
})
export class CustomLoaderComponent {

}
