import { Component, Input } from '@angular/core';
import { ProductPricing } from 'src/app/models/product-pricing.model';
import { BuyingGroups } from 'src/app/models/buying-groups.model';
import { Warehouse } from 'src/app/models/warehouse.model';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CrudService } from 'src/app/services/crud.service';

@Component({
  selector: 'app-products-pricing',
  templateUrl: './products-pricing.component.html',
  styleUrls: ['./products-pricing.component.css']
})
export class ProductsPricingComponent {
  @Input() key: string;
  @Input() item: any;
  detailsLoading: boolean = false;
  data$: Observable<ProductPricing[]>;
  buyingGroups$: Observable<BuyingGroups[]>;
  warehouses$: Observable<Warehouse[]>;
  apiUrl: string = environment.baseApiUrl;

  constructor(private crudService: CrudService) { }

  ngOnInit() {
    this.loadData();
  }

  loadData() {
    this.data$ = this.crudService.newGetData(`${this.apiUrl}products-pricing/${this.key}`);
    this.buyingGroups$ = this.crudService.newGetData(`${this.apiUrl}buying-groups`);
    this.warehouses$ = this.crudService.newGetData(`${this.apiUrl}warehouses`);
  }

  insertRow(e: any) {
    e.data.product_code = this.key; 
    this.crudService.insertData(`${this.apiUrl}products-pricing`, e.data).subscribe((result) => {
      this.loadData();
    });
  }

  updateRow(e: any) {
    console.log(e);
    this.crudService.updateData(`${this.apiUrl}products-pricing`, e.key.id, e.newData).subscribe((result) => {
      this.loadData();
    });
  }

}
