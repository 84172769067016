import { Component } from '@angular/core';

@Component({
    selector: 'app-custom-card',
    templateUrl: './custom-card.component.html',
    styleUrls: ['./custom-card.component.css'],
    standalone: false
})
export class CustomCardComponent {

}
