import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { DxTreeListModule, DxScrollViewModule, DxDataGridModule, DxCheckBoxModule,
  DxButtonModule, DxTabPanelModule,  DxSelectBoxModule, DxSwitchModule, DxDropDownButtonModule,
  DxToolbarModule, DxPopupModule, DxNumberBoxModule, DxFormModule, DxLookupModule, DxTagBoxModule,
  DxSchedulerModule, DxDropDownBoxModule, DxMenuModule, DxListModule, DxFileUploaderModule,
  DxColorBoxModule, DxTabsModule, DxDrawerModule, DxTooltipModule,DxValidatorModule, DxTextBoxModule,
  DxValidationGroupModule, DxBoxModule, DxDateBoxModule, DxContextMenuModule, DxValidationSummaryModule, DxTreeViewModule,
  DxLoadPanelModule, DxRadioGroupModule, DxTextAreaModule } from 'devextreme-angular';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import enMessages from 'devextreme/localization/messages/en.json';
import { locale, loadMessages } from "devextreme/localization";
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DataGridComponent } from './data-grid/data-grid.component';
import { DetailViewComponent } from './detail-view/detail-view.component';
import { HeaderComponent } from './header/header.component';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { AuthGuard } from './auth.guard.service';
import { ProductGroupsComponent } from './settings/product-groups/product-groups.component';
import { ProductComponent } from './settings/product/product.component';
import { PromotionGroupsComponent } from './settings/promotion-groups/promotion-groups.component';
import { PromotionPeriodsComponent } from './settings/promotion-periods/promotion-periods.component';
import { FormsModule } from '@angular/forms';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ReportsComponent } from './reports/reports.component';
import { PromotionNamesComponent } from './settings/promotion-names/promotion-names.component';
import { ProfileComponent } from './settings/profile/profile.component';
import { BuyingGroupsComponent } from './settings/buying-groups/buying-groups.component';
import { SlotTypesComponent } from './settings/slot-types/slot-types.component';
import { PrintLayoutComponent } from './reports/print-layout/print-layout.component';
import { MspRangeComponent } from './reports/msp-range/msp-range.component';
import { FilterComponent } from './reports/filter/filter.component';
import { WeekRangeSelectorComponent } from './week-range-selector/week-range-selector.component';
import { EventsComponent } from './settings/events/events.component';
import { PromotionHeaderComponent } from './promotion-header/promotion-header.component';
import { PromotionStatusComponent } from './settings/promotion-status/promotion-status.component';
import { PromotionTypeComponent } from './settings/promotion-type/promotion-type.component';
import { PromotionDetailsComponent } from './promotion-details/promotion-details.component';
import { ProductSelectionComponent } from './product-selection/product-selection.component';
import { PromotionGroupItemsComponent } from './settings/promotion-group-items/promotion-group-items.component';
import { StorePositionsComponent } from './settings/store-positions/store-positions.component';
import { CoreRangeHeadersComponent } from './settings/core-range-headers/core-range-headers.component';
import { CoreRangeDetailsComponent } from './settings/core-range-details/core-range-details.component';
import { AlmAdviceComponent } from './reports/alm-advice/alm-advice.component';
import { ClonePlanningDataComponent } from './clone-planning-data/clone-planning-data.component';
import { PromotionSummary } from './reports/promotion-summary/promotion-summary.component';
import { CloneSlottingDataComponent } from './clone-slotting-data/clone-slotting-data.component';
import { ImportPromotionComponent } from './import-promotion/import-promotion.component';
import { PromotionAdviceComponent } from './reports/promotion-advice/promotion-advice.component';
import { CoreRangeComponent } from './reports/core-range/core-range.component';
import { ClonePromotionComponent } from './clone-promotion/clone-promotion.component';
import { CustomLoaderComponent } from './custom-loader/custom-loader.component';
import { PromotionAllowancesComponent } from './settings/promotion-allowances/promotion-allowances.component';
import { MediaTypesComponent } from './settings/media-types/media-types.component';
import { MediaPlanningComponent } from './media-planning/media-planning.component';
import { ProductCategoriesComponent } from './settings/product-categories/product-categories.component';
import { PromotionCategoriesComponent } from './settings/promotion-categories/promotion-categories.component';
import { ProductsComponent } from './settings/products-component/products-component.component';
import { SuppliersComponent } from './settings/suppliers/suppliers.component';
import { PromotionGroupSelectionComponent } from './promotion-group-selection/promotion-group-selection.component';
import { MixMatchComponent } from './settings/mix-match/mix-match.component';
import { MixMatchLevelComponent } from './settings/mix-match-level/mix-match-level.component';
import { MixMatchItemComponent } from './settings/mix-match-item/mix-match-item.component';
import { MixMatchSelectionComponent } from './settings/mix-match-selection/mix-match-selection.component';
import { PromotionRebatesComponent } from './settings/promotion-rebates/promotion-rebates.component';
import { CustomCardComponent } from './custom-card/custom-card.component';
import { WarehouseComponent } from './settings/warehouse/warehouse.component';
import { MixMatchQtyComponent } from './settings/mix-match-qty/mix-match-qty.component';
import { RebateDetailsComponent } from './rebate-details/rebate-details.component';
import { PromotionConfirmationComponent } from './reports/promotion-confirmation/promotion-confirmation.component';
import { MemberAdviceComponent } from './reports/ilr-reports/member-advice/member-advice.component';
import { PosPressBriefReportComponent } from './reports/pos-press-brief-report/pos-press-brief-report.component';
import { RawHostDataComponent } from './raw-host-data/raw-host-data.component';
import { ConfigurationComponent } from './reports/configuration/configuration.component';
import { PromotionDetailsExportComponent } from './reports/promotion-details-export/promotion-details-export.component';
import { BuyInTheGreenComponent } from './reports/thirsty-camel-reports/buy-in-the-green/buy-in-the-green.component';
import { WarehousePricingComponent } from './warehouse-pricing/warehouse-pricing.component';
import { PromotionConfirmationIlrComponent } from './reports/ilr-reports/promotion-confirmation-ilr/promotion-confirmation-ilr.component';
import { CloneCoreRangeComponent } from './clone-core-range/clone-core-range.component';
import { PromotionDetailsByGroupComponent } from './promotion-details-by-group/promotion-details-by-group.component';
import { CopyCorePricingComponent } from './settings/copy-core-pricing/copy-core-pricing.component';
import { PromotionSummaryThirsty } from './reports/thirsty-camel-reports/promotion-summary-thirsty/promotion-summary-thirsty.component';
import { PromotionAdviceThirstyComponent } from './reports/thirsty-camel-reports/promotion-advice-thirsty/promotion-advice-thirsty.component';
import { DatePipe } from '@angular/common';
import { ByGroupDetailsComponent } from './by-group-details/by-group-details.component';
import { PosPressBriefReportThirstyComponent } from './reports/thirsty-camel-reports/pos-press-brief-report-thirsty/pos-press-brief-report-thirsty.component';
import { NewMemberAdviceComponent } from './reports/ilr-reports/new-member-advice/new-member-advice.component';
import { PromotionBuyingDatesComponent } from './settings/promotion-buying-dates/promotion-buying-dates.component';
import { RangeFocusExportComponent } from './reports/range-focus-export/range-focus-export.component';
import { ProductsPricingComponent } from './settings/products-pricing/products-pricing.component';
import { PromotionMixMatchComponent } from './reports/promotion-mix-match/promotion-mix-match.component';

loadMessages(enMessages);
locale('en');


@NgModule({ declarations: [
        AppComponent,
        CustomLoaderComponent,
        CustomCardComponent,
        EventsComponent,
        PromotionPeriodsComponent,
        DataGridComponent,
        HeaderComponent,
        DetailViewComponent,
        ProductGroupsComponent,
        ProductComponent,
        PromotionGroupsComponent,
        DashboardComponent,
        ReportsComponent,
        PromotionNamesComponent,
        ProfileComponent,
        BuyingGroupsComponent,
        SlotTypesComponent,
        PrintLayoutComponent,
        MspRangeComponent,
        FilterComponent,
        WeekRangeSelectorComponent,
        PromotionHeaderComponent,
        PromotionStatusComponent,
        PromotionTypeComponent,
        PromotionDetailsComponent,
        ProductSelectionComponent,
        PromotionGroupItemsComponent,
        StorePositionsComponent,
        CoreRangeHeadersComponent,
        CoreRangeDetailsComponent,
        AlmAdviceComponent,
        ClonePlanningDataComponent,
        PromotionSummary,
        PromotionSummaryThirsty,
        CloneSlottingDataComponent,
        ImportPromotionComponent,
        PromotionAdviceComponent,
        CoreRangeComponent,
        ClonePromotionComponent,
        PromotionAllowancesComponent,
        MediaTypesComponent,
        MediaPlanningComponent,
        ProductCategoriesComponent,
        PromotionCategoriesComponent,
        ProductsComponent,
        SuppliersComponent,
        PromotionGroupSelectionComponent,
        MixMatchComponent,
        MixMatchLevelComponent,
        MixMatchItemComponent,
        MixMatchSelectionComponent,
        PromotionRebatesComponent,
        WarehouseComponent,
        MixMatchQtyComponent,
        RebateDetailsComponent,
        PromotionConfirmationComponent,
        MemberAdviceComponent,
        PosPressBriefReportComponent,
        RawHostDataComponent,
        ConfigurationComponent,
        PromotionDetailsExportComponent,
        BuyInTheGreenComponent,
        WarehousePricingComponent,
        PromotionConfirmationIlrComponent,
        CloneCoreRangeComponent,
        PromotionDetailsByGroupComponent,
        CopyCorePricingComponent,
        PromotionAdviceThirstyComponent,
        ByGroupDetailsComponent,
        PosPressBriefReportThirstyComponent,
        NewMemberAdviceComponent,
        PromotionBuyingDatesComponent,
        RangeFocusExportComponent,
        ProductsPricingComponent,
        PromotionMixMatchComponent
    ],
    bootstrap: [AppComponent], imports: [BrowserModule,
        AppRoutingModule,
        DxTreeListModule,
        DxScrollViewModule,
        DxDataGridModule,
        DxCheckBoxModule,
        DxButtonModule,
        DxSelectBoxModule,
        DxTabPanelModule,
        DxSwitchModule,
        DxDropDownButtonModule,
        DxToolbarModule,
        DxPopupModule,
        DxNumberBoxModule,
        DxFormModule,
        DxLookupModule,
        DxTagBoxModule,
        DxSchedulerModule,
        FormsModule,
        DxDropDownBoxModule,
        BrowserAnimationsModule,
        DxMenuModule,
        DxListModule,
        DxFileUploaderModule,
        DxColorBoxModule,
        DxTabsModule,
        DxDrawerModule,
        DxTooltipModule,
        DxValidatorModule,
        DxTextBoxModule,
        DxValidationGroupModule,
        DxBoxModule,
        DxDateBoxModule,
        DxContextMenuModule,
        DxValidationSummaryModule,
        DxTreeViewModule,
        DxLoadPanelModule,
        DxRadioGroupModule,
        DxTextAreaModule], providers: [
        AuthGuard,
        DatePipe,
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule { }

platformBrowserDynamic().bootstrapModule(AppModule);
