import { Component, Input } from '@angular/core';
import { PromotionHeader } from 'src/app/models/promotion-header.model';
import { CrudService } from 'src/app/services/crud.service';
import { environment } from 'src/environments/environment';

import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { PageOrientation } from 'pdfmake/interfaces';
import { PageSize } from 'pdfmake/interfaces';
import { GroupProductService } from '../group-products-service.service';
import { Base64Images } from 'src/app/services/base64-images.service';
import { ReportStylesService } from 'src/app/services/report-styles.service';
import { DateFormatService } from 'src/app/services/date-format.service';
import { LoadingService } from '../report-loading.service';
import { DateService } from 'src/app/services/date.service';

const pdf = pdfMake;
pdf.vfs = pdfFonts.vfs;
@Component({
    selector: 'app-promotion-summary',
    templateUrl: './promotion-summary.component.html',
    styleUrls: ['./promotion-summary.component.css'],
    standalone: false
})
export class PromotionSummary {
  @Input() name: string;
  @Input() reportComponent: string;
  private promoDetailsUrl: string = environment.baseApiUrl + 'promotion-details';
  private mediaPlanUrl: string = environment.baseApiUrl + 'media-planning';
  private apiUrl: string = environment.baseApiUrl;
  data: any[];
  headers: PromotionHeader[];
  promotionName: string = '';
  pageNumber: number = 1;
  userDetails: any;
  disclaimer: string;
  constructor(private crudService: CrudService, private dateService:DateService, private groupProductService: GroupProductService,private dateFormatService:DateFormatService,private base64Image: Base64Images,private reportStylesService: ReportStylesService, private loadingService: LoadingService)
  {
    const userDetailsData = localStorage.getItem("userDetails");
    if (userDetailsData) {
      this.userDetails = JSON.parse(userDetailsData || '{}');
  } else {
      // Handle the null case, e.g., set to a default value or log an error
      this.userDetails = {}; // or some other default value
  }
  }
pagePromotion: string = '';
promotionIndex: number = 0;
transformDataForPdfMake(promotions: any[]): any[] {
  if (!Array.isArray(promotions)) {
    console.error('Expected an array for promotions, received:', promotions);
    return [];
  }

  // Initialize the table with only the column headers
  const tableBody = [
    [
      { text: 'Product Name', style: 'tableHeader' },
      { text: '', style: 'tableHeader' },
      { text: 'Price', style: 'tableHeader' },
      { text: 'Product Name', style: 'tableHeader' },
      { text: '', style: 'tableHeader' },
      { text: 'Price', style: 'tableHeader' }
    ]
  ];

  // Process each promotion
  promotions.forEach(promotion => {
    const products = promotion.products;
    if (!Array.isArray(products) || products.length === 0) {
      console.error('Products not in expected array format or empty:', products);
      return;
    }


    // Add a promotion-specific header row before listing products
    const promotionHeaderRow:any = [
      { text: `${promotion.promotionName} (${this.dateFormatService.changeDateFormat(promotion.products[0].promotion_start)} - ${this.dateFormatService.changeDateFormat(promotion.products[0].promotion_end)})`, style: 'promoDateHeader', colSpan: 6, alignment: 'left' },
      {}, {}, {}, {}, {}
    ];
    tableBody.push(promotionHeaderRow);

    const halfLength = Math.ceil(products.length / 2);
    for (let i = 0; i < halfLength; i++) {
      const leftProduct = products[i];
      const rightProduct = i + halfLength < products.length ? products[i + halfLength] : undefined;

      const row = [
        { text: leftProduct ? leftProduct.promotion_group_name : '', style: 'bodyStyle' },
        { text: leftProduct ? '' : '', style: 'bodyStyle' },
        { text: leftProduct ? leftProduct.price : '', style: 'bodyStyle', alignment: 'center' },
        { text: rightProduct ? rightProduct.promotion_group_name : '', style: 'bodyStyle' },
        { text: rightProduct ? '' : '', style: 'bodyStyle' },
        { text: rightProduct ? rightProduct.price : '', style: 'bodyStyle', alignment: 'center' }
      ];
      tableBody.push(row);
    }
  });

    const subheader = {
      stack: [
        {
          columns: [
            {
              text: `${this.name}`,
              style: 'header',
              alignment: 'left',
            },
            {
                image: this.base64Image.getBase64Image(this.userDetails.data.location_token),
                width: 150,
                alignment: 'right',
                margin: [0, 0, 0, 0]  // Adjust margin as needed for the image
            }
    
          ]
        }
    
    
        ]
      };
const secondHeader = {
stack: [
  {
    columns: [
      {
        text: `${this.headers[this.promotionIndex].promotion_name}`,

        style: 'subheader',
        alignment: 'left',
        width: 'auto',
        margin: [10, 0, 0, 0]  // Adjust margin as needed for the text
      },
      {
        text: `${this.selectedFilter}`,
        
        style: 'subheader',
        alignment: 'right',
        width: 'auto',
        margin: [160, 0, 10, 0]  // Adjust margin as needed for the text
      }
    ]
  }
]
}
  // Create a single table with dynamic data
  const tableDefinition = {

    table: {
      headerRows: 1,
      widths: [ '*', 50, 50, '*', 50, 50 ],
      body: tableBody
    },
    layout: {
      hLineWidth: () => 0,
      vLineWidth: (i:any) => (i === 3 ? 1 : 0), // Vertical line after the third column
      hLineColor: () => 'white',
      vLineColor: () => 'white',
      fillColor: (rowIndex:any) => (rowIndex % 2 === 0 ? '#d9f0f1' : null),
    },
    pageBreak: 'after'
  };

  return [subheader, secondHeader, tableDefinition];
}





styleRow(row: any[], style: string): any[] {
  return row.map(cell => {
      // If the cell is an object, merge the style with the existing properties
      if (typeof cell === 'object' && cell !== null) {
          return { ...cell, style: style };
      }
      // If the cell is a simple value, create a new object with text and style
      else {
          return { text: cell, style: style };
      }
  });
}



pdfCreator(promotions: any[]) {
  if (!this.data || this.data.length === 0) {
    console.error('Data is not available');
    return;
  }

  const transformedDataArray = this.transformDataForPdfMake(promotions);
  const styles: any = this.reportStylesService.getStyles();


  const documentDefinition = {
      content: [...transformedDataArray],
      pageOrientation: 'landscape' as PageOrientation,
      pageSize: 'A4' as PageSize,
      styles: styles,
      border: [false,false,false,false],
      info: {
        title: `Promotion Summary ${this.selectedFilter}`,
      },
      footer: (currentPage: number, pageCount: number) => {
        return {

          stack: [
            {
              columns: [
                {
                  text: `${this.disclaimer}`,
                  style: 'footerDisclaimer',
                  fontSize:6
                },
               
                {
                  text: `Promoflo © 2024 OnTap Data Inc. \n
                  Created: ${this.dateService.getCurrentDateTime()} \n
                  
                  Page ${currentPage} of ${pageCount}`,
                  style: 'copyrightFooter',
                  fontSize:6

                }
              ]
            }
            ]
        };
    }
  };
  this.promotionIndex = 0;
  const filename = `Promotion_Summary_${this.filtered_by_promotion}_${this.selectedFilter}.pdf`;
  pdfMake.createPdf(documentDefinition).download(filename);
  this.loadingService.toggleLoading(false);
}



filtered_by_promotion: string = '';
async getPromoData(e: any) {
  if (e.groupSelected == null) {
    console.log("No data to filter.");
    return;
  }
  this.disclaimer = e.disclaimer;
  await this.crudService.getData(`${this.apiUrl}promotion-headers?promotion_period_id=${e.promotionSelected.value}&buying_group_id=${e.groupSelected.value}`).toPromise().then((result: any) => {
    const sortedHeaders = result.sort((a:any, b:any) => {
      const descriptionA = a.description || '';
      const descriptionB = b.description || '';
      return descriptionA.localeCompare(descriptionB, 'en', { numeric: true });
    });

    this.headers = sortedHeaders;

  this.selectedFilter = e.filtered.buying_group_name;
  this.filtered_by_promotion = e.promotionNameSelected;
  this.crudService.getData(`${this.mediaPlanUrl}?promotion_period_id=${e.promotionSelected.value}&buying_group_id=${e.groupSelected.value}&edlp`).subscribe((res: any) => {
    
    const firstHeaderId = res[0].promotion_header_id;
    const promoDataEDLPChecked = res.map((promoProduct: any) => {
        return {
          ...promoProduct,
          promotion_header_id: promoProduct.edlp == 1 ? firstHeaderId : promoProduct.promotion_header_id
        };
      });
    
    
    if (Array.isArray(res)) {
      this.data = this.groupProductService.groupByPromotions(promoDataEDLPChecked, sortedHeaders);
    } else {
      console.error("Expected an array from the service, received:", promoDataEDLPChecked);
      this.data = []; // Set to empty array to prevent errors downstream
    }
    this.pdfCreator(this.data);
  });
});
}

@Input() selectedFilter: string = '';
handleSelectedFilter(filter: string) {
  this.selectedFilter = filter;
  }
}

