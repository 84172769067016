
import { Component, ViewChild, ChangeDetectionStrategy } from '@angular/core';
import { Product } from 'src/app/models/product.model';
import { CrudService } from 'src/app/services/crud.service';
import CustomStore from 'devextreme/data/custom_store';
import { ProductGroup } from 'src/app/models/product-group.model';
import { Suppliers } from 'src/app/models/suppliers.model';
import { Warehouse } from 'src/app/models/warehouse.model';
import { DxDataGridComponent } from 'devextreme-angular';
import { environment } from 'src/environments/environment';
import { HorizontalMenuService } from 'src/app/services/horizontalmenu.service';
import { NotifyService } from 'src/app/services/notify.service';
import { Observable } from 'rxjs';
import { BuyingGroups } from 'src/app/models/buying-groups.model';
import { ProductPricing } from 'src/app/models/product-pricing.model';

@Component({
  selector: 'app-products-component',
  templateUrl: './products-component.component.html',
  styleUrls: ['./products-component.component.css']
})
export class ProductsComponent {
  product_group: any;
  @ViewChild('productsGrid', { static: false }) productsGrid: DxDataGridComponent;
  product: Product[];
  dataSource: CustomStore;
  productGroupStore: CustomStore;
  count: number = 0;
  importDisabled: boolean = false;
  isStored: boolean = false;
  private apiUrl: string = environment.baseApiUrl;
  editProdGroups: boolean = false;
  editCategoryGroups: boolean = false;
  groupList: ProductGroup[];
  supplierList: Suppliers[];
  warehouseList: Warehouse[];
  product_groups: ProductGroup[] = [];
  showFilterRow: boolean | null | undefined = false;
  buyingGroups$: Observable<BuyingGroups[]>;
  productsPricing$: Observable<ProductPricing[]>;
  unmatchedProducts: any[] = [];
  unmatchedProductsMap: { [productCode: string]: boolean } = {};
  overflowOptions = [
    { value: 1, name: 'Product Groups' },
    { value: 2, name: 'Product Categories' },
    { value: 3, name: 'Suppliers' },
    { value: 4, name: 'Mix n Match'}
  ];
  toggleStateItems = [
    { id: 'all', name: 'All' },
    { id: 'matched', name: 'Matched' },
    { id: 'unmatched', name: 'Unmatched' }
  ];

  currentToggleState = this.toggleStateItems[0]; // default to 'All'
  dropdownButtonText = 'Filter By'; // Initial text for the drop-down button

  constructor(private crudService: CrudService,
              private horizontalMenuService: HorizontalMenuService,
              private notifyService: NotifyService)
  {
    this.loadUnmatchedProducts();
    this.loadData();
  }

  async loadData(filterType: 'all' | 'matched' | 'unmatched' = 'all') {
    this.dataSource = new CustomStore<Product>({
        key: 'id',
        load: async (loadOptions) => {
            try {
                let data = await this.crudService.getData(`${this.apiUrl}products`).toPromise();
                //this.buyingGroups$ = this.crudService.getData(`${this.apiUrl}buying-groups`);
                if (filterType === 'matched') {
                    data = data.filter((product:any) => !this.unmatchedProductsMap[product.product_code]);
                } else if (filterType === 'unmatched') {
                    data = data.filter((product:any) => this.unmatchedProductsMap[product.product_code]);
                }
                // No filtering for 'all'
                return data;
            } catch (error) {
                console.error('Error loading data:', error);
                throw error;
            }
        },
      insert: async (values) => {
        console.log(values);
        return await new Promise(async (resolve) => {
          const result: any = await this.crudService.insertData(`${this.apiUrl}products`, values)
          .subscribe((result: any) => {
            this.notifyService.tellUser(result);
            resolve(result);
          });
        });
      },
      update: async (key, values) => {
        console.log(values);
        return await new Promise(async (resolve) => {
          await this.crudService.updateData(`${this.apiUrl}products`, key, values)
          .subscribe((result: any) => {
            this.notifyService.tellUser(result);
            resolve(result);
          });
        });
      },
      remove: async (key) => {
        return await new Promise(async (resolve) => {
          const result: any = await this.crudService.deleteData(`${this.apiUrl}products`, key)
          .subscribe((result: any) => {
            this.notifyService.tellUser(result);
            resolve(result);
          });
        });
      }
    });
    this.crudService.getData(`${this.apiUrl}suppliers`).subscribe((result: any) => {
      this.supplierList = result;
    });
    this.crudService.getData(`${this.apiUrl}warehouses`).subscribe((result: any) => {
      this.warehouseList = result;
    });
    this.loadProductGroups();
  }
  clearFilter() {
    this.productsGrid.instance.clearFilter();
  }
onDropdownSelectionChanged(e: any) {
  console.log("Event received:", e);
  if (!e.item) {
    console.error('No item in selection changed event', e);
    return; // Prevent further execution if item is not present
  }

  this.dropdownButtonText = `${e.item.name}`;
  this.currentToggleState = e.item;
  console.log("Current toggle state set to:", this.currentToggleState);

  switch (this.currentToggleState.id) {
    case 'all':
      this.loadData('all');
      break;
    case 'matched':
      this.loadData('matched');
      break;
    case 'unmatched':
      this.loadData('unmatched');
      break;
    default:
      this.loadData('all');
  }
  this.productsGrid.instance.refresh();
}

// Function to toggle the filter row visibility
toggleFilterRow(): void {
  this.showFilterRow = !this.showFilterRow;
  this.clearFilter();
}
  async loadProductGroups() {
    this.productGroupStore = new CustomStore<any>({
      key: 'id',
      load: (loadOptions) => {
        try {
          const result = this.crudService.getData(`${this.apiUrl}product-groups`, loadOptions).toPromise();
          return result;
        } catch (error) {
          console.error('Error loading data:', error);
          throw error;
        }
      },
      byKey: (key) => {
        // Find the item in the preloaded data
        const items = this.product_groups || [];
        const item = items.find((item:any) => item.id === key);
        // Return a resolved promise with the found item
        console.log("items:",item)
        return Promise.resolve(item);
      }
    });
  }
  itemClick(e:any){
    if(e.itemData.value == 4){
      this.horizontalMenuService.seteditMixMatch(true);
    }
    if(e.itemData.value == 3){
      this.horizontalMenuService.seteditSuppliers(true);
    }
    if(e.itemData.value == 2){
      this.horizontalMenuService.seteditProductCategories(true);
    }
    if(e.itemData.value == 1){
      this.horizontalMenuService.seteditProdGroups(true);
    }
  }

  async importProductFile(): Promise<void> {
    this.importDisabled = true;
    const config = await this.crudService.getData(`${this.apiUrl}config/LatestHostFile`).subscribe((result: any[]) => {
      if (result.length > 0) {
        const config_string = result[0].config_string;
        this.crudService.getData(`${this.apiUrl}host-data?date_added=${config_string}`).subscribe(() => {
          this.importDisabled = false;
          this.refreshDataGrid();
        });
      } else {
          this.crudService.getData(`${this.apiUrl}host-data`).subscribe(() => {
          this.importDisabled = false;
        });
      }
    });
  }

  calculateGpAmount(row: Product)
  {
    if(row.sell_price !== null && row.cost_inc_gst !== null) {
      return row.sell_price - row.cost_inc_gst;
    }
    return undefined;
  }
  getGroupList(event: ProductGroup[]) {
    this.groupList = event;
  }
  calculateGpPercent(row: Product)
  {
    if(row.sell_price !== null && row.sell_price !== 0 && row.cost_inc_gst !== null) {
      return ((row.sell_price - row.cost_inc_gst) / row.sell_price) * 100;
    }
    return undefined;
  }

  refreshDataGrid() {
    this.loadData();
    this.productsGrid.instance.refresh();
  }

  onEditorPreparing(e: any) {
    if (e.parentType === "searchPanel") {
      e.editorOptions.onValueChanged = undefined;
      e.editorOptions.onEnterKey = function(arg: any) {
      e.component.searchByText(arg.component.option("value"));
      }
    }
  }
  async loadUnmatchedProducts() {
      try {
          const response = await this.crudService.getData(`${this.apiUrl}products-unmatched`).toPromise();
          this.unmatchedProducts = (typeof response === 'string') ? JSON.parse(response) : response;
          this.unmatchedProducts.forEach(product => {
              this.unmatchedProductsMap[product.product_code] = true;
          });
      } catch (error) {
          console.error('Failed to load unmatched products:', error);
      }
  }


  onRowPrepared(e: any): void {
    if (e.data && this.unmatchedProducts) {
        const foundProduct = this.unmatchedProducts.find(product => product.product_code === e.data.product_code);

        if (foundProduct) {
            e.rowElement.className = e.rowElement.className.replace("dx-row-alt", "");
            e.rowElement.style.cssText = 'background-color: rgb(230, 163, 86, .2)';
            e.rowElement.classList.add('unmatched-product');
        }
    }
}


}
