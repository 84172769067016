import { Component, ViewChild } from '@angular/core';
import { CrudService } from 'src/app/services/crud.service';
import CustomStore from 'devextreme/data/custom_store';
import { environment } from 'src/environments/environment';
import { ConfirmationService } from 'src/app/services/confirmation.service';
import { HorizontalMenuService } from 'src/app/services/horizontalmenu.service';
import { DxDataGridComponent } from 'devextreme-angular';
import { NotifyService } from 'src/app/services/notify.service';
@Component({
    selector: 'app-slot-types',
    templateUrl: './slot-types.component.html',
    styleUrls: ['./slot-types.component.css'],
    standalone: false
})
export class SlotTypesComponent {
  public dataSource: CustomStore;
  private apiUrl: string = environment.baseApiUrl;
  editSlotTypes: boolean;
  storePositions: any[] = [];
  weekCount: Array<number> = Array(20).fill(0).map((_, index) => index + 1);
  @ViewChild("slotTypesGrid", { static: false }) public dataGrid: DxDataGridComponent;
  constructor(private crudService: CrudService,
    private confirmationService:ConfirmationService,
    private horizontalMenuService:HorizontalMenuService,
    private notifyService:NotifyService) {
    this.dataSource = new CustomStore<any>({
      key: 'id',
      load: async () => {
        return await new Promise((resolve) => {
          this.crudService.getData(`${this.apiUrl}slot-types`).subscribe((result: any) => {
            resolve({data: result, totalCount: result.length});
          })
        })
      },
      insert: async (values) => {
        const userDetails = localStorage.getItem('userDetails');
        const parsedDetails = JSON.parse(userDetails || '{}');
        values.group_id = parsedDetails.data.group_id;
        values.location_id = parsedDetails.data.location_id;
        return await new Promise(async (resolve) => {
          const result: any = await this.crudService.insertData(`${this.apiUrl}slot-types`, values)
          .subscribe((result: any) => {
            this.notifyService.tellUser(result);
            resolve(result);
          });
        });
      },
      update: async (key, values) => {
        console.log(key, values);
        return await new Promise(async (resolve) => {
          const result: any = await this.crudService.updateData(`${this.apiUrl}slot-types`, key, values)
          .subscribe((result: any) => {
            this.notifyService.tellUser(result);
            resolve(result);
          });
        });
      },
      remove: async (key) => {
        return await new Promise(async (resolve) => {
          const result: any = await this.crudService.deleteData(`${this.apiUrl}slot-types`, key)
          .subscribe((result: any) => {
            this.notifyService.tellUser(result);
            resolve(result);
          });
        });
      }
    })
    this.horizontalMenuService.editSlotTypes$.subscribe((value: boolean) => {
      this.editSlotTypes = value;
      this.crudService.getData(`${this.apiUrl}store-positions`).subscribe((result: any) => {
        this.storePositions = result.data;
      });
    });
  }

  onColorValueChanged(e: any, cellData: any) {
    cellData.setValue(e.value);
    //e.component.cellValue(e.rowIndex, 'color', e.value);
  }

  onCellPrepared(e: any) {  
    if (e.rowType === "data" && e.column.dataField == "colour") {  
      e.cellElement.style.backgroundColor = e.value;  
      }  
  }  

  onHiding(event:any){
    this.confirmationService.checkUnsaved(event, this.dataGrid, (value:boolean) => this.horizontalMenuService.seteditSlotTypes(value))
  }
}
