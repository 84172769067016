import { Component, Output, EventEmitter } from '@angular/core';
import { CrudService } from '../services/crud.service';
import { environment } from 'src/environments/environment';
import { NotifyService } from '../services/notify.service';
import { BuyingGroups } from '../models/buying-groups.model';

@Component({
    selector: 'app-clone-core-range',
    templateUrl: './clone-core-range.component.html',
    styleUrls: ['./clone-core-range.component.css'],
    standalone: false
})
export class CloneCoreRangeComponent {
  @Output() cloneCoreRangeValues: EventEmitter<any> = new EventEmitter<any>();
  currentCoreRange: any = {};
  buyingGroups: BuyingGroups[] = [];
  buyingGroupId: number;
  startDate: string;
  endDate: string;
  rangeName: string;
  rebateClone: boolean = false;
  loadingVisible: boolean = false;
  apiUrl: string = environment.baseApiUrl;

  constructor(private crudService: CrudService,
    private notifyService: NotifyService) {
      this.crudService.getData(`${this.apiUrl}buying-groups`).subscribe((data: any) => {
        this.buyingGroups = data
      });
  }
  ngAfterContentChecked() {
    if(localStorage.getItem('selected-range') !== null) {
      this.currentCoreRange = JSON.parse(localStorage.getItem('selected-range') || '');
      this.currentCoreRange.range_name = this.currentCoreRange.range_name + ' (COPY)';
    }
  }

  setBuyingGroup(e: any)
  {
    this.buyingGroupId = e.value;
  }

  setStartDate(e: any)
  {
    this.startDate = e.value;
  }

  setEndDate(e: any)
  {
    this.endDate = e.value;
  }

  setRangeName(e: any)
  {
    this.rangeName = e.value;
  }

  setRebateClone(e: any)
  {
    this.rebateClone = e.value;
  }

  cancel() {
    this.cloneCoreRangeValues.emit(false);
  }

  cloneCoreRange()
  {
    this.loadingVisible = true;
    let path: string = `clone-core-range`;
    if(this.buyingGroupId !== undefined)
      this.currentCoreRange.buying_group_id = this.buyingGroupId;
    if(this.startDate !== undefined)
      this.currentCoreRange.start_date = this.startDate;
    if(this.endDate !== undefined)
      this.currentCoreRange.end_date = this.endDate;
    if(this.rangeName !== undefined)
      this.currentCoreRange.range_name = this.rangeName;
    if(this.rebateClone !== undefined && this.rebateClone === true)
      path = `clone-core-range?clone_rebate=1`
    try {
      this.crudService.insertData(`${this.apiUrl}${path}`, this.currentCoreRange).subscribe((data: any) => {
        console.log(data);
        this.loadingVisible = true;
        if(data.success) {
          this.notifyService.tellUser(data);
          this.loadingVisible = false;
          this.cloneCoreRangeValues.emit(true);
        } else {
          this.notifyService.tellUser(data);
          this.loadingVisible = false;
          this.cloneCoreRangeValues.emit(false);
        }
      });
    } catch (error) {
      console.log(error);
    }
  }

}
