import { Component } from '@angular/core';
import { CrudService } from 'src/app/services/crud.service';
import CustomStore from 'devextreme/data/custom_store';
import { environment } from 'src/environments/environment';
import { NotifyService } from '../services/notify.service';
import { HorizontalMenuService } from '../services/horizontalmenu.service';
import { DateFormatService } from '../services/date-format.service';

@Component({
    selector: 'app-raw-host-data',
    templateUrl: './raw-host-data.component.html',
    styleUrls: ['./raw-host-data.component.css'],
    standalone: false
})
export class RawHostDataComponent {
  dataSource: CustomStore;
  fileDates: string[] = [];
  accountCodes: string[] = [];
  dataLoading: Boolean = false;
  fileDate: string = '';
  accountCode: string = '';
  viewHostData: boolean = false;
  rollBackDisabled: boolean = true;
  private apiUrl: string = environment.baseApiUrl;

  constructor(private crudService: CrudService,
              private notify: NotifyService,
              private horizontalMenuService: HorizontalMenuService,
              private dateFormatService: DateFormatService)
  {

    const userDetails = localStorage.getItem('userDetails');
    const parsedDetails = JSON.parse(userDetails || '{}');
    this.crudService.getData(`${this.apiUrl}host-data-raw/${parsedDetails.data.location_token}`).subscribe((result: any) => {
      this.fileDates = result.data;
    });
    this.crudService.getData(`${this.apiUrl}host-data/${parsedDetails.data.location_token}`).subscribe((result: any) => {
      this.accountCodes = result.data;
    });
    this.horizontalMenuService.viewHostData$.subscribe((value: boolean) => {
      this.viewHostData = value;
    });
  }

  setDate(e: any)
  {
    this.fileDate = e.value;
  }

  setAccountCode(e: any)
  {
    this.accountCode = e.value;
  }

  loadData()
  {
    if(this.fileDate === '' || this.accountCode === '') {
      this.notify.returnError('Please select a file date and account code');
      return;
    }
    this.dataSource = new CustomStore({
      key: 'liquorfile_number',
      load: async () => {
        this.dataLoading = true;
        return await new Promise((resolve) => {
          this.crudService.getData(`${this.apiUrl}host-data-raw?date_added=${this.fileDate}&account_code=${this.accountCode}`).subscribe((result: any) => {
            resolve(result);
            this.rollBackDisabled = false;
            this.dataLoading = false;
          }, (error: any) => {
            console.log(error);
            this.dataLoading = false;
          });
        })
      },
      insert: async (values: any) => {
        //
      },
      update: async (key, values: any) => {
        //
      },
      remove: async (key) => {
        //
      }
    });
  }

  rollBackData()
  {
    if(this.fileDate === '' || this.accountCode === '') {
      this.notify.returnError('Please select a file date and account code');
      return;
    }
    this.rollBackDisabled = true;
    this.dataLoading = true;
    this.crudService.getData(`${this.apiUrl}host-data?date_added=${this.dateFormatService.yearFirstDateFormat(this.fileDate)}&account_code=${this.accountCode}&rollback`).subscribe((result: any) => {
      this.rollBackDisabled = false;
      this.dataLoading = false;
      this.notify.tellUser(result);
    });
  }

}
