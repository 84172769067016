import { ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { CrudService } from 'src/app/services/crud.service';
import CustomStore from 'devextreme/data/custom_store';
import { LocalDataService } from 'src/app/services/local-data.service';
import { environment } from 'src/environments/environment';
import { ConfirmationService } from 'src/app/services/confirmation.service';
import { HorizontalMenuService } from 'src/app/services/horizontalmenu.service';
import { DxDataGridComponent } from 'devextreme-angular';
import { PromotionService } from 'src/app/services/promotions-data-sync.service';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
    selector: 'app-promotion-type',
    templateUrl: './promotion-type.component.html',
    styleUrls: ['./promotion-type.component.css'],
    standalone: false
})
export class PromotionTypeComponent {
  dataSource: any;
  private apiUrl: string = environment.baseApiUrl + 'promotion-types';
  editPromotionTypes:boolean;
  @ViewChild("promotionTypeGrid", { static: false }) public dataGrid: DxDataGridComponent;
  
  constructor(private crudService: CrudService,
      private localData: LocalDataService,
      private confirmationService:ConfirmationService,
    private horizontalMenuService:HorizontalMenuService,
    private promotionService:PromotionService, private cdr: ChangeDetectorRef,private route: ActivatedRoute,
    private router: Router) {
      this.horizontalMenuService.editPromotionTypes$.subscribe((value: boolean) => {
        this.editPromotionTypes = value;
      });
    


        // Check if the current route is 'promotion-status'
        const currentRoute = this.route.snapshot.routeConfig?.path;
        if (currentRoute === 'promotion-types') {
          this.loadData();
        }
 
    
  }
  loadData() {
    this.dataSource = new CustomStore<any>({
      key: 'id',
      load: async () => {
        return await new Promise((resolve) => {
          this.crudService.getData(this.apiUrl).subscribe((result: any) => {
            localStorage.setItem('promotion-types', JSON.stringify(result));
            resolve({data: result, totalCount: result.length});
          })
        })
    },
      insert: async (values: any) => {
        const userDetails = localStorage.getItem('userDetails');
        const parsedDetails = JSON.parse(userDetails || '{}');
        values.group_id = parsedDetails.data.group_id;
        values.location_id = parsedDetails.data.location_id;
        return await new Promise(async (resolve) => {
          const result: any = await this.crudService.insertData(this.apiUrl, values)
          .subscribe((result: any) => {
            this.localData.addData('promotion-types', values);
            this.promotionService.requestRefreshGrid();
            resolve(result);
          });
        });
      },
      update: async (key, values) => {
        return await new Promise(async (resolve) => {
        const userDetails = localStorage.getItem('userDetails');
        const parsedDetails = JSON.parse(userDetails || '{}');
        values.group_id = parsedDetails.data.group_id;
        values.location_id = parsedDetails.data.location_id;
          const result: any = await this.crudService.updateData(this.apiUrl, key, values)
          .subscribe((result: any) => {
            this.localData.updateData('promotion-types', key, values);
            this.promotionService.requestRefreshGrid();
            resolve(result);
          });
        });
      },
      remove: async (key) => {
        return await new Promise(async (resolve) => {
          const result: any = await this.crudService.deleteData(this.apiUrl, key)
          .subscribe((result: any) => {
            this.localData.removeData('promotion-types', key);
            resolve(result);
          });
        });
      }
    })
  }
  onHiding(event:any){
    this.confirmationService.checkUnsaved(event, this.dataGrid, (value:boolean) => this.horizontalMenuService.seteditPromotionTypes(value))
        .then((canHide:boolean) => {
          //if canHide checks if use can hide profile popup.
          if(canHide){
            this.horizontalMenuService.seteditPromotionTypes(false);
          }
        });
      }
}
