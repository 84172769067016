

<dx-popup
  [(visible)]="productGroup.editProdGroups"
  [hideOnOutsideClick]="true"
  [showTitle]="true"
  title="Product Groups"
  height="95%"
  width="60%"
  [showCloseButton]="true"
  (onHiding)="productGroup.onHiding($event)">
    <app-product-groups #productGroup></app-product-groups>
</dx-popup>
<dx-popup
  [(visible)]="productCategories.editProdCategories"
  [hideOnOutsideClick]="true"
  [showTitle]="true"
  title="Product Categories"
  height="95%"
  width="60%"
  [showCloseButton]="true"
  (onHiding)="productCategories.onHiding($event)">
    <app-product-categories #productCategories></app-product-categories>
</dx-popup>
<dx-popup
  [(visible)]="suppliers.editSuppliers"
  [hideOnOutsideClick]="true"
  [showTitle]="true"
  title="Suppliers"
  height="95%"
  width="60%"
  [showCloseButton]="true"
  (onHiding)="suppliers.onHiding($event)">
    <app-suppliers #suppliers></app-suppliers>
</dx-popup>
<dx-popup
  [(visible)]="mixMatch.editMixMatch"
  [hideOnOutsideClick]="true"
  [showTitle]="true"
  title="Mix n Match"
  height="95%"
  width="60%"
  [showCloseButton]="true"
  (onHiding)="mixMatch.onHiding($event)">
    <app-mix-match #mixMatch></app-mix-match>
</dx-popup>
<div class="nav_id">
  <p>
    <u>products</u>
  </p>
</div>

<div class="gridContainer">
<dx-data-grid #productsGrid
  [dataSource]="dataSource"
  [remoteOperations]="false"
  [allowColumnReordering]="true"
  [showBorders]="true"
  [wordWrapEnabled]="true"
  [rowAlternationEnabled]="true"
  keyExpr="id"
  height="95%"
  width="100%"
  [allowColumnReordering]="true"
  [allowColumnResizing]="true"
  [columnResizingMode]="'widget'"
  [paging]="{ pageSize: 20 }"
  (onRowPrepared)="onRowPrepared($event)"
>
<!-- (onEditorPreparing)="onEditorPreparing($event)" -->

<dxo-scrolling mode="virtual"></dxo-scrolling>
<dxo-sorting mode="multiple"></dxo-sorting>
<!-- <dxo-grouping #expand [autoExpandAll]="false"></dxo-grouping> -->
<dxo-header-filter [visible]="true"></dxo-header-filter>
<dxo-group-panel [visible]="true"></dxo-group-panel>
<dxo-search-panel [visible]="true"></dxo-search-panel>
<dxo-filter-row [visible]="showFilterRow"></dxo-filter-row>
<dxo-state-storing
  [enabled]="true"
  type="localStorage"
  storageKey="productsState">
</dxo-state-storing>
<dxo-column-chooser
    #columnChooser
    [enabled]="true"
    mode="select"
  >
    <dxo-position
    my="right top"
    at="right bottom"
    of=".dx-datagrid-column-chooser-button"
  ></dxo-position>
  </dxo-column-chooser>
<dxo-toolbar>
  <dxi-item  location="after">
    <div *dxTemplate>
  <dx-button class="search-toggle"
  icon="search"
  [class.active]="showFilterRow"
  (onClick)="toggleFilterRow()">
  </dx-button>
  </div>
  </dxi-item>
  <dxi-item name="groupPanel"></dxi-item>
  <dxi-item name="addRowButton"></dxi-item>
  <dxi-item><dx-button hint="Import Host File" icon="download" [disabled]="importDisabled" (onClick)="importProductFile()"></dx-button></dxi-item>
  <dxi-item location="after" >
    <div class="create_category_button" *dxTemplate>
    <dx-drop-down-button class="category-icon"
    [elementAttr]="{ class: 'category-icon-border' }"
    hint="Product Configuration"
        [useSelectMode]="false"
        width="22"
        height="22"
        [items]="overflowOptions"
        displayExpr="name"
        keyExpr="id"
        stylingMode="text"
        [showArrowIcon]="false"
        [dropDownOptions]="{
          width: 180,

      }"
      (onItemClick)="itemClick($event)"
      >
    </dx-drop-down-button>
  </div>
  </dxi-item>
  <dxi-item name="columnChooserButton"></dxi-item>
  <dxi-item><dx-button icon="refresh" hint="Refresh Grid" (onClick)="refreshDataGrid()"></dx-button></dxi-item>
  <dxi-item location="after">
    <div *dxTemplate="let data of 'content'">
      <dx-drop-down-button
          [(items)]="toggleStateItems"
          displayExpr="name"
          keyExpr="id"
          width="120px"
          [text]="dropdownButtonText"
          [useSelectMode]="true"
          [(selectedItem)]="currentToggleState"
          (onSelectionChanged)="onDropdownSelectionChanged($event)"
         >
      </dx-drop-down-button>
    </div>
  </dxi-item>
  <dxi-item name="searchPanel"></dxi-item>
</dxo-toolbar>
<dxo-editing
    mode="popup"
    [allowAdding]="true"
    [allowUpdating]="true"
    [allowDeleting]="true"
    labelLocation="top"
  >
  <dxo-texts addRow="Create a Product"></dxo-texts>
  <dxo-popup [showTitle]="true" title="Create Product"[hideOnOutsideClick]="true" width="1000" height="700"></dxo-popup>
    <dxo-form>
        <dxi-item itemType="group" [colCount]="5" [colSpan]="2">
            <dxi-item dataField="product_code" [colSpan]="1"></dxi-item>
            <dxi-item dataField="product_name" [colSpan]="4"></dxi-item>
        </dxi-item>
        <dxi-item itemType="group" [colCount]="6" [colSpan]="2">
            <dxi-item dataField="product_media_name" [colSpan]="6"></dxi-item>
            <dxi-item dataField="warehouse_id" [colSpan]="2"></dxi-item>
            <dxi-item dataField="supplier_code" [colSpan]="2"></dxi-item>
            <dxi-item dataField="product_group_code" [colSpan]="2"></dxi-item>
            <dxi-item dataField="carton_quantity" [colSpan]="2"></dxi-item>
            <dxi-item dataField="packs_per_carton" [colSpan]="2"></dxi-item>
            <dxi-item dataField="sales_unit" [colSpan]="2"></dxi-item>
            <dxi-item dataField="cost_ex_gst" [colSpan]="2"></dxi-item>
            <dxi-item dataField="pick_fee" [colSpan]="2"></dxi-item>
            <dxi-item dataField="allowance_group" [colSpan]="2"></dxi-item>
            <dxi-item dataField="allowance_promotion" [colSpan]="2"></dxi-item>
            <dxi-item dataField="allowance_total" [colSpan]="2"></dxi-item>
            <dxi-item dataField="allowance_universal" [colSpan]="2"></dxi-item>
            <dxi-item dataField="department_code" [colSpan]="2"></dxi-item>
            <dxi-item dataField="brand_code" [colSpan]="2"></dxi-item>
            <dxi-item dataField="sub_brand_code" [colSpan]="2"></dxi-item>
            <dxi-item dataField="liquorfile_number" [colSpan]="2"></dxi-item>
            <dxi-item dataField="liquorfile_parent_single" [colSpan]="2"></dxi-item>
            <dxi-item dataField="product_size" [colSpan]="1"></dxi-item>
            <dxi-item dataField="product_ordering_code" [colSpan]="1"></dxi-item>
        </dxi-item>
    </dxo-form>
  </dxo-editing>
  <dxi-column dataField="warehouse_id" dataType="string" caption="Warehouse" width="auto" [fixed]="true">
    <dxi-validation-rule type="required" message="Warehouse is required"></dxi-validation-rule>
    <dxo-lookup [dataSource]="warehouseList" loadMode="raw" displayExpr="name" valueExpr="id"></dxo-lookup>
  </dxi-column>
  <dxi-column dataField="product_code" dataType="string" caption="Code" width="auto" [fixed]="true">
    <dxi-validation-rule type="required" message="Product code is required"></dxi-validation-rule>
  </dxi-column>
  <dxi-column dataField="product_name" dataType="string" caption="Name" width="250" [fixed]="true">
    <dxi-validation-rule type="required" message="Product name is required"></dxi-validation-rule>
  </dxi-column>
  <dxi-column dataField="product_media_name" dataType="string" caption="Media Name" width="250"></dxi-column>
<dxi-column dataField="supplier_code" dataType="string" caption="Supplier" width="auto">
  <dxi-validation-rule type="required" message="Supplier is required"></dxi-validation-rule>
  <dxo-lookup [dataSource]="supplierList" loadMode="raw" displayExpr="supplier_name" valueExpr="supplier_code"></dxo-lookup>
</dxi-column>
  <dxi-column dataField="product_ordering_code" dataType="string" caption="Ordering Code"width="auto"></dxi-column>
  <dxi-column dataField="product_group_name" dataType="string" caption="Group Name"width="auto">
    <dxi-validation-rule type="required" message="Product group is required"></dxi-validation-rule>
  </dxi-column>
  <dxi-column dataField="product_group_code" dataType="string" caption="Product Group" width="auto">
    <dxo-lookup [dataSource]="productGroupStore" loadMode="raw" displayExpr="product_group_name" valueExpr="product_group_code"></dxo-lookup>
    <dxi-validation-rule type="required" message="Product group is required"></dxi-validation-rule>
  </dxi-column>
  <dxi-column caption="Units" alignment="center">
  <dxi-column dataField="carton_quantity" dataType="integer" [allowSearch]="false" caption="Carton Qty"width="auto">
    <dxi-validation-rule type="required" message="Carton Qty. is required"></dxi-validation-rule>
  </dxi-column>
  <dxi-column dataField="packs_per_carton" dataType="integer" [allowSearch]="false" caption="Per CTN"width="auto"></dxi-column>
  <dxi-column dataField="sales_unit" dataType="integer" [allowSearch]="false" caption="Per Unit"width="auto"></dxi-column>
  </dxi-column>
  <dxi-column caption="Costs" alignment="center">
  <dxi-column dataField="pick_fee" [format]="{type: 'currency', precision: 2}" dataType="number" [allowSearch]="false" caption="Pick Fee"width="auto"></dxi-column>
  <dxi-column dataField="cost_ex_gst"[format]="{type: 'currency', precision: 2}" dataType="number" [allowSearch]="false" caption="Cost"width="auto"></dxi-column>
  </dxi-column>
  <dxi-column caption="Allowances" alignment="center">
  <dxi-column dataField="allowance_group" [format]="{type: 'currency', precision: 2}" dataType="number" [allowSearch]="false" caption="Allowance Group"width="auto"></dxi-column>
  <dxi-column dataField="allowance_promotion" [format]="{type: 'currency', precision: 2}" dataType="number" [allowSearch]="false" caption="Allowance Promotion"width="auto"></dxi-column>
  <dxi-column dataField="allowance_total" [format]="{type: 'currency', precision: 2}" dataType="number" [allowSearch]="false" caption="Allowance Total"width="auto"></dxi-column>
  </dxi-column>
  <dxi-column dataField="allowance_universal" [format]="{type: 'currency', precision: 2}" dataType="number" [allowSearch]="false" caption="Uni. Deal"width="auto"></dxi-column>
  <dxi-column dataField="department_code" dataType="string" caption="Department Code"width="auto"></dxi-column>
  <dxi-column dataField="brand_code" dataType="string" caption="Brand Code"width="auto"></dxi-column>
  <dxi-column dataField="sub_brand_code" dataType="string" caption="Sub Brand Code"width="auto"></dxi-column>
  <dxi-column dataField="liquorfile_number" dataType="string" caption="Liquorfile Number"width="auto"></dxi-column>
  <dxi-column dataField="product_size" dataType="number" caption="Product Size"width="auto"></dxi-column>
  <dxi-column dataField="liquorfile_parent_single" dataType="string" caption="Liquorfile Parent Single"width="auto"></dxi-column>
  <dxi-column dataField="id" dataType="integer" [allowSearch]="false" [visible]="false"></dxi-column>
  <dxo-summary>
    <dxi-group-item column="product_group_name" summaryType="count"> </dxi-group-item>
  </dxo-summary>
  <dxo-master-detail [enabled]="true" template="detail"></dxo-master-detail>
      <div *dxTemplate="let item of 'detail'">
          <app-products-pricing [key]="item.data.product_code" [item]="item"></app-products-pricing>
      </div>
</dx-data-grid>

</div>
