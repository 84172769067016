import { Component, Input, Output, EventEmitter } from '@angular/core';
import { CrudService } from 'src/app/services/crud.service';
import { environment } from 'src/environments/environment';
import { NotifyService } from 'src/app/services/notify.service';

@Component({
    selector: 'app-copy-core-pricing',
    templateUrl: './copy-core-pricing.component.html',
    styleUrls: ['./copy-core-pricing.component.css'],
    standalone: false
})
export class CopyCorePricingComponent {
  coreRangeHeaders: any;
  copyFrom: number;
  loadingVisible: boolean = false;
  @Input() copyTo: number;
  @Output() loadParentData: EventEmitter<any> = new EventEmitter();

  constructor(private crudService: CrudService, private notifyService: NotifyService) {
    this.crudService.getData(environment.baseApiUrl + 'core-range-headers').subscribe((coreRangeHeadersResult: any) => {
      this.crudService.getData(environment.baseApiUrl + 'buying-groups').subscribe((buyingGroupsResult: any) => {
        let coreRangeHeaders = coreRangeHeadersResult.data;
        coreRangeHeaders.forEach((header: any) => {
          const buyingGroup = buyingGroupsResult.find((bg: any) => bg.id === header.buying_group_id);
          header.buying_group_name = buyingGroup ? buyingGroup.buying_group_name : 'Not Found';
          header.lookup_name = `${header.buying_group_name} - ${header.range_name}`;
        });
        this.coreRangeHeaders = coreRangeHeaders;
      });
    });
  }

  setCoreRangeHeader(e: any) {
    console.log('Value changed:', e);
    this.copyFrom = e.value;
  }

  copyPricing(header: any) {
    this.loadingVisible = true;
    this.crudService.getData(`${environment.baseApiUrl}copy-core-range-pricing?from_core_range_header_id=${this.copyFrom}&to_core_range_header_id=${this.copyTo}`).subscribe((result: any) => {
      if(result.success) {
        this.notifyService.tellUser(result);
        this.loadingVisible = false;
        this.loadParentData.emit();
      } else {
        this.notifyService.returnError(result);
        this.loadingVisible = false;
      }
    });
  }
}
