import { Component, Input } from '@angular/core';
import { CrudService } from 'src/app/services/crud.service';
import { environment } from 'src/environments/environment';
import { LoadingService } from '../report-loading.service';
import { DateService } from 'src/app/services/date.service';

@Component({
  selector: 'app-range-focus-export',
  templateUrl: './range-focus-export.component.html',
  styleUrls: ['./range-focus-export.component.css']
})
export class RangeFocusExportComponent {
  @Input() name: string;
  @Input() selectedFilter: string = '';
  apiUrl: string = environment.baseApiUrl;
  currentDateTime: string | null;

  constructor(private crudService: CrudService,
    private loadingService:LoadingService,
    private dateService: DateService) {
      this.currentDateTime = this.dateService.getCurrentDateTime() || null;
     }

  handleSelectedFilter(filter: string) {
    this.selectedFilter = filter;
    }

    getCoreRangeData(e: any) {
      console.log(e);
      console.log(e.coreRange.value.id);
      this.crudService.getExportData(
        `${this.apiUrl}export/rebate-focus?core_range_header_id=${e.coreRange.value.id}`,
        {},
        'blob'
      ).subscribe(
        (response: Blob) => {
              // Create a Blob from the response
              const blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  
              // Create a download link
              const downloadLink = document.createElement('a');
              const url = window.URL.createObjectURL(blob);
              downloadLink.href = url;
              downloadLink.download = 'Rebate & Focus Export.xlsx';
              downloadLink.download = `RebateFocusFlagForALMOrderingPortal-${this.currentDateTime}.xlsx`;
              // Append the link, trigger the download, then remove the link
              document.body.appendChild(downloadLink);
              downloadLink.click();
              document.body.removeChild(downloadLink);
              window.URL.revokeObjectURL(url);
              this.loadingService.toggleLoading(false);
          },
          (error:any) => {
              console.error('Error:', error);
          }
      );
    }

}
