import { Component, ViewChild } from '@angular/core';
import { CrudService } from 'src/app/services/crud.service';
import CustomStore from 'devextreme/data/custom_store';
import { environment } from 'src/environments/environment';
import { HorizontalMenuService } from 'src/app/services/horizontalmenu.service';
import { ConfirmationService } from 'src/app/services/confirmation.service';
import { DxDataGridComponent } from 'devextreme-angular';

@Component({
    selector: 'app-promotion-categories',
    templateUrl: './promotion-categories.component.html',
    styleUrls: ['./promotion-categories.component.css'],
    standalone: false
})
export class PromotionCategoriesComponent {
  private apiUrl: string = environment.baseApiUrl;
  dataSource: any;
  editPromoCategories: boolean = false;

  @ViewChild("promotionCategoryGrid", { static: false }) public dataGrid: DxDataGridComponent;

  constructor(private crudService: CrudService,
      private horizontalMenuService: HorizontalMenuService, private confirmationService:ConfirmationService) 
  {
    this.dataSource = new CustomStore<any>({
      key: 'id',
      load: async () => {
        return await new Promise((resolve) => {
          this.crudService.getData(`${this.apiUrl}planning-categories`).subscribe((result: any) => {
            localStorage.setItem('promotion-categories', JSON.stringify(result));
            resolve({data: result, totalCount: result.length});
          })
        })
    },
      insert: async (values) => {
        const userDetails = localStorage.getItem('userDetails');
        const parsedDetails = JSON.parse(userDetails || '{}');
        values.group_id = parsedDetails.data.group_id;
        values.location_id = parsedDetails.data.location_id;
        console.log(values);
        return await new Promise(async (resolve) => {
          const result: any = await this.crudService.insertData(`${this.apiUrl}planning-categories`, values)
          .subscribe((result: any) => {
            resolve(result);
          });
        });
      },
      update: async (key, values) => {
        return await new Promise(async (resolve) => {
          const result: any = await this.crudService.updateData(`${this.apiUrl}planning-categories`, key, values)
          .subscribe((result: any) => {
            resolve(result);
          });
        });
      },
      remove: async (key) => {
        return await new Promise(async (resolve) => {
          const result: any = await this.crudService.deleteData(`${this.apiUrl}planning-categories`, key)
          .subscribe((result: any) => {
            resolve(result);
          });
        });
      }
    });
    this.horizontalMenuService.editPromotionCategories$.subscribe((value: boolean) => {
      this.editPromoCategories = value;
    });
  }

  onHiding(event:any){
    this.confirmationService.checkUnsaved(event, this.dataGrid, (value:boolean) => this.horizontalMenuService.seteditPromotionCategories(value));
  }

}
