import { ChangeDetectorRef, Component,OnInit,ViewChild } from '@angular/core';
import { CrudService } from 'src/app/services/crud.service';
import CustomStore from 'devextreme/data/custom_store';
import { LocalDataService } from 'src/app/services/local-data.service';
import { environment } from 'src/environments/environment';
import { ConfirmationService } from 'src/app/services/confirmation.service';
import { HorizontalMenuService } from 'src/app/services/horizontalmenu.service';
import { DxDataGridComponent } from 'devextreme-angular';
import { PromotionService } from 'src/app/services/promotions-data-sync.service';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
    selector: 'app-promotion-status',
    templateUrl: './promotion-status.component.html',
    styleUrls: ['./promotion-status.component.css'],
    standalone: false
})
export class PromotionStatusComponent  implements OnInit{
  dataSource: any;
  private apiUrl: string = environment.baseApiUrl + 'promotion-statuses';
  editStatus:boolean;
  promotionStatusTypes: PromotionStatusTypes[] = [{type: "open", name: "Open"}, {type: "restricted", name: "Restricted"}, {type: "closed", name: "Closed"}];
  @ViewChild("promotionStatusGrid", { static: false }) public dataGrid: DxDataGridComponent;

  constructor(private crudService: CrudService,
      private localData: LocalDataService,
      private confirmationService:ConfirmationService,
    private horizontalMenuService:HorizontalMenuService,
    private promotionService:PromotionService, private cdr:ChangeDetectorRef, private route: ActivatedRoute,
    private router: Router) {

    this.horizontalMenuService.editStatus$.subscribe((value: boolean) => {
      this.editStatus = value;
    });
  }
  ngOnInit(): void {

      // Check if the current route is 'promotion-status'
      const currentRoute = this.route.snapshot.routeConfig?.path;
      if (currentRoute === 'promotion-status') {
        this.loadData();
      }

  }
  loadData() {
    console.log("loadData")
    this.dataSource = new CustomStore<any>({
      key: 'id',
      load: async () => {
        return await new Promise((resolve) => {
          this.crudService.getData(this.apiUrl).subscribe((result: any) => {
            localStorage.setItem('promotion-statuses', JSON.stringify(result));
            resolve({data: result, totalCount: result.length});
          })
        })
    },
      insert: async (values: PromotionStatuses) => {
        const userDetails = localStorage.getItem('userDetails');
        const parsedDetails = JSON.parse(userDetails || '{}');
        values.group_id = parsedDetails.data.group_id;
        values.location_id = parsedDetails.data.location_id;
        return await new Promise(async (resolve) => {
          const result: any = await this.crudService.insertData(this.apiUrl, values)
          .subscribe((result: any) => {
            this.localData.addData('promotion-statuses', values);
            this.promotionService.requestRefreshGrid();
            resolve(result);
          });
        });
      },
      update: async (key, values: PromotionStatuses) => {
        return await new Promise(async (resolve) => {
        const userDetails = localStorage.getItem('userDetails');
        const parsedDetails = JSON.parse(userDetails || '{}');
        values.group_id = parsedDetails.data.group_id;
        values.location_id = parsedDetails.data.location_id;
          const result: any = await this.crudService.updateData(this.apiUrl, key, values)
          .subscribe((result: any) => {
            this.localData.updateData('promotion-statuses', key, values);
            this.promotionService.requestRefreshGrid();
            resolve(result);
          });
        });
      },
      remove: async (key) => {
        return await new Promise(async (resolve) => {
          const result: any = await this.crudService.deleteData(this.apiUrl, key)
          .subscribe((result: any) => {
            this.localData.removeData('promotion-statuses', key);
            resolve(result);
          });
        });
      }
    })
  }

  updateLocalStorage(values: PromotionStatuses): void
  {
    const localDataString = localStorage.getItem('promotion-statuses');
    const localData = localDataString ? JSON.parse(localDataString) : null;

    if (localData) {
      localStorage.setItem('promotion-statuses', JSON.stringify([...localData, values]));
    }

  }

  onHiding(event:any){
    this.confirmationService.checkUnsaved(event, this.dataGrid, (value:boolean) => this.horizontalMenuService.seteditStatus(value))
        .then((canHide:boolean) => {
          //if canHide checks if use can hide profile popup.
          if(canHide){
            this.horizontalMenuService.seteditStatus(false);
          }
        });
      }
}

export class PromotionStatuses
{
  id: number;
  group_id: number;
  location_id: number;
  status: number;
  name: string;
  type: string;
}

export class PromotionStatusTypes
{
  type: string;
  name: string;
}

