import { Component, ViewChild } from '@angular/core';
import { CrudService } from 'src/app/services/crud.service';
import { LocalDataService } from 'src/app/services/local-data.service';
import CustomStore from 'devextreme/data/custom_store';
import { environment } from 'src/environments/environment';
import notify from 'devextreme/ui/notify';
import { ConfirmationService } from 'src/app/services/confirmation.service';
import { HorizontalMenuService } from 'src/app/services/horizontalmenu.service';
import { DxDataGridComponent } from 'devextreme-angular';
@Component({
    selector: 'app-store-positions',
    templateUrl: './store-positions.component.html',
    styleUrls: ['./store-positions.component.css'],
    standalone: false
})
export class StorePositionsComponent {
  public dataSource: CustomStore;
  private apiUrl: string = environment.baseApiUrl;
  editStorePositions: boolean;
@ViewChild("storePositionGrid", { static: false }) public dataGrid: DxDataGridComponent;
  
  constructor(private crudService: CrudService, 
    private localData: LocalDataService,
    private confirmationService:ConfirmationService,
    private horizontalMenuService:HorizontalMenuService) {
    this.dataSource = new CustomStore<any>({
      key: 'id',
      load: async () => {
        return await new Promise((resolve) => {
          this.crudService.getData(`${this.apiUrl}store-positions`).subscribe((result: apiResponse) => {
            if(result.success) {
              localStorage.setItem('store-positions', JSON.stringify(result.data));
              resolve({data: result.data, totalCount: result.totalCount});
            } else {
              notify({message: result.data[0].message}, 'error');
            }
          })
        })
      },
      insert: async (values: StorePositions) => {
        return await new Promise(async (resolve) => {
          values = this.setLocationDetails(values);
          await this.crudService.insertData(`${this.apiUrl}store-positions`, values)
          .subscribe((result: any) => {
            if(result.success) {
              notify({message: result.data}, 'success');
              this.localData.addData('store-positions', values);
              resolve(result);
            } else {
              notify({message: result.data}, 'error');
            }
          });
        });
      },
      update: async (key: number, values: StorePositions) => {
        return await new Promise(async (resolve) => {
          values = this.setLocationDetails(values);
          await this.crudService.updateData(`${this.apiUrl}store-positions`, key, values)
          .subscribe((result: any) => {
            if(result.success) {
              notify({message: result.data}, 'success');
              this.localData.updateData('store-positions', key, values);
              resolve(result);
            } else {
              notify({message: result.data}, 'error');
            }
          });
        });
      },
      remove: async (key: number) => {
        return await new Promise(async (resolve) => {
          await this.crudService.deleteData(`${this.apiUrl}store-positions`, key)
          .subscribe((result: any) => {
            if(result.success) {
              notify({message: result.data}, 'success');
              this.localData.removeData('store-positions', key);
              resolve(result);
            } else {
              notify({message: result.data}, 'error');
            }
          });
        });
      }
    })
    this.horizontalMenuService.editStorePositions$.subscribe((value: boolean) => {
      this.editStorePositions = value;
    });
  }

  setLocationDetails(values: StorePositions): StorePositions
  {
    const userDetails = localStorage.getItem('userDetails');
    const parsedDetails = JSON.parse(userDetails || '{}');
    values.group_id = parsedDetails.data.group_id;
    values.location_id = parsedDetails.data.location_id;
    return values;
  }
  onHiding(event:any){
    this.confirmationService.checkUnsaved(event, this.dataGrid, (value:boolean) => this.horizontalMenuService.seteditStorePositions(value))
  }
}

export class apiResponse
{
  data: Array<any>;
  totalCount: number;
  success: boolean;
}

export class StorePositions
{
  id: number;
  group_id: number;
  location_id: number;
  position_name: string;
  position_description: string;
  created_at: string;
  updated_at: string;
}
