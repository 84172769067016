<div class="report-container">

    <div id="reportFilter" class="filter-container">
        <div class="dx-fieldset">
            <div class="dx-field">
                <!-- Promotion field -->
                <div *ngIf="name != 'Buy in the Green' && name != 'Core Range' && name != 'Rebate & Focus Export'" class="dx-field-container">
                    <div class="dx-field-label report_label">Promotion:</div>
                    <div class="dx-field-value">
                        <dx-select-box id="promotionNames"  displayExpr="promotion_name" valueExpr="promotion_period_id" [dataSource]="promotionNames" (onValueChanged)="storePromotionName($event)" width="auto">
                            <dx-validator [validationGroup]="validationGroup">
                                <dxi-validation-rule
                                  type="required"
                                  message=" "
                                  
                                ></dxi-validation-rule>
                                
                              </dx-validator></dx-select-box>
                    </div>
                </div>
                <!-- Promotion field -->
                <div *ngIf="name == 'Buy in the Green'" class="dx-field-container">
                    <div class="dx-field-label report_label">Start Date:</div>
                    <div class="dx-field-value">
                        <!-- Use dxDateBox for date selection -->
                        <dx-date-box (onValueChanged)="storeBuyInGreenDate($event)"
                            (value)="selectedDate"
                            pickerType="calendar"
                            displayFormat="dd-MM-yyyy"
                            placeholder="Select a date"
                            [showClearButton]="true"
                            width="auto"
                            type="date" >
                            <dx-validator [validationGroup]="validationGroup">
                                <dxi-validation-rule
                                  type="required"
                                  message=" "
                                ></dxi-validation-rule>
                                
                              </dx-validator>
                        </dx-date-box>
                    </div>
              
                </div>
                <div *ngIf="name == 'Buy in the Green'" class="dx-field-container">
                <div class="dx-field-label report_label">Weeks:</div>
                <div class="dx-field-value">
                    <!-- Use dxDateBox for date selection -->
                    <dx-number-box (onValueChanged)="storeBuyInGreenWeeksSelected($event)"
                    [value]="4"
                    [min]="4"
                    [max]="8"
                    [showSpinButtons]="true"
                    [inputAttr]="{ 'aria-label': 'Min And Max' }"
                    ></dx-number-box>
                </div>
            </div>
                <!-- Filter by field -->
                <div class="dx-field-container">
                    <div class="dx-field-label report_label">Group:</div>
                    <div class="dx-field-value">
                        <dx-select-box id="promotionFilter" displayExpr="buying_group_name" valueExpr="id" [dataSource]="buyingGroups" (onValueChanged)="storeGroupData($event)" width="auto">
                            <dx-validator [validationGroup]="validationGroup">
                                <dxi-validation-rule 
                                  type="required"
                                  message=" "
                                ></dxi-validation-rule>
                                
                              </dx-validator></dx-select-box>
                    </div>
                </div>
                <div *ngIf="name == 'Core Range' || name == 'Rebate & Focus Export'" class="dx-field-container">
                    <div class="dx-field-label report_label">Core Range:</div>
                    <div class="dx-field-value">
    <dx-select-box id="coreRangeFilter"
                   [searchEnabled]="true"
                   placeholder="Select a range..."
                   [searchExpr]="['id', 'range_name']"
                   searchMode="contains"
                   displayExpr="range_name"
                   [dataSource]="coreRangeHeaders"
                   (onValueChanged)="storeCoreRangeData($event)"
                   width="auto"
                   [(value)]="selectedRange">
    </dx-select-box>
</div>
                </div>
                <!-- Filter by field -->
                <div *ngIf="name == 'Promotion Confirmation'" class="dx-field-container">
                    <div class="dx-field-label report_label">Supplier:</div>
                    <div class="dx-field-value">
                        <dx-select-box id="promotionFilter" [searchEnabled]="true" placeholder="select..." [searchExpr]="['supplier_code', 'supplier_name']" searchMode="contains" displayExpr="supplier_name" valueExpr="supplier_code" [dataSource]="suppliersView" (onOpened)="getSuppliers()" (onValueChanged)="storeSupplierFilterData($event)" width="auto">
                            
                            <dx-validator [validationGroup]="validationGroup">
                                <dxi-validation-rule 
                                  type="required"
                                  message=" "
                                ></dxi-validation-rule>
                                
                              </dx-validator></dx-select-box>
                              
                              
                    </div>
                </div>
            </div>
        </div>
        
        <div *ngIf="name !== 'Promotion Details Export' && name !== 'Rebate & Focus Export'" class="dropdown">
        
            <div class="disclaimer-title" (click)="toggleDropdown()"><p> Set disclaimer </p>
            <span>{{ isOpen ? '&#9207;' : '&#9205;' }}</span></div>
        
          <div class="dropdown-content" [style.display]="isOpen ? 'block' : 'none'">
            <div> <dx-text-area [(value)]="disclaimer" [valueChangeEvent]="'keyup'" [maxLength]="maxLength"></dx-text-area></div>
            <dx-button type="default" (click)="submitDisclaimer()" height="25px" width="100px"><p>Set Disclaimer</p></dx-button>
            
            <span>{{disclaimer.length}}/{{maxLength}}</span>
          </div>
      </div>
        <div class="options-filter">
  <div *ngIf="name == 'ALM Advice' || name == 'Pos & Press Brief Report'" class="check-box"> <dx-check-box text="Export to Spreadsheet" (onValueChanged)="onValueChanged($event)"></dx-check-box></div>
  <div *ngIf="name == 'Core Range'" class="check-box"> <dx-check-box text="Rebate" (onValueChanged)="onRebateCheck($event)"></dx-check-box></div>
  <div *ngIf="name == 'Promotion Confirmation'" class="check-box"> <dx-check-box text="Exclude Price" (onValueChanged)="onPriceExclude($event)"></dx-check-box></div>
  <div *ngIf="name != 'Promotion Confirmation' || 'Core Range' || 'ALM Advice' || 'Rebate & Focus Export' " class="check-box"></div>

        <div class="button-wrapper">
            
            <dx-button *ngIf='!loading'  class="dx-widget dx-button dx-button-mode-contained dx-button-default dx-button-has-text" style="width: 130px;" printSectionId="report" [useSubmitBehavior]="true" [validationGroup]="validationGroup" (onClick)="validateAndSubmit()">
                <div class="dx-button-content" *ngIf="name == 'Promotion Details Export'; else elseBlock">
                    <div *ngIf='!loading' class="dx-button-text">Export<i class="dx-icon-export"></i></div>
                    <div *ngIf='loading' class="lds-dual-ring"></div>
                </div>
                <ng-template #elseBlock>
                    <div *ngIf='!loading' class="dx-button-text">Download<i class="dx-icon-download"></i></div>
                    <div *ngIf='loading' class="lds-dual-ring"></div>

                    
                </ng-template>
                
            </dx-button>
            
            <dx-button *ngIf='loading' class="dx-widget dx-button dx-button-mode-contained dx-button-default dx-button-has-text loading-button" [disabled]="true" style="width: 130px;" printSectionId="report">
                <div class="dx-button-content">
                    <div *ngIf='loading' class="lds-dual-ring"></div>
                </div>
            </dx-button>

        </div>
        </div>
        
    </div>
    <dx-validation-summary validationGroup="reportForm"></dx-validation-summary>

  </div>
