import { Component } from '@angular/core';

@Component({
    selector: 'app-print-layout',
    templateUrl: './print-layout.component.html',
    styleUrls: ['./print-layout.component.css'],
    standalone: false
})
export class PrintLayoutComponent {

}
