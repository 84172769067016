import { Component, ViewChild, Injectable, OnInit, ViewContainerRef, Type, ComponentRef } from '@angular/core';
import { ReportService, Tab, Report, favoriteReport } from '../services/reports.service';
import { CrudService } from '../services/crud.service';
import DataSource from 'devextreme/data/data_source';
import { UserDetails } from '../models/user-details';
import { environment } from 'src/environments/environment';
import { AlmAdviceComponent } from './alm-advice/alm-advice.component';
import { PromotionSummary } from './promotion-summary/promotion-summary.component';
import { PromotionSummaryThirsty } from './thirsty-camel-reports/promotion-summary-thirsty/promotion-summary-thirsty.component';
import { DxScrollViewComponent } from 'devextreme-angular';
import { PromotionAdviceComponent } from './promotion-advice/promotion-advice.component';
import { CoreRangeComponent } from './core-range/core-range.component';
import { PromotionConfirmationComponent } from './promotion-confirmation/promotion-confirmation.component';
import { MemberAdviceComponent } from './ilr-reports/member-advice/member-advice.component';
import { PosPressBriefReportComponent } from './pos-press-brief-report/pos-press-brief-report.component';
import { ConfigurationComponent } from './configuration/configuration.component';
import { PromotionDetailsExportComponent } from './promotion-details-export/promotion-details-export.component';
import { BuyInTheGreenComponent } from './thirsty-camel-reports/buy-in-the-green/buy-in-the-green.component';
import { PromotionConfirmationIlrComponent } from './ilr-reports/promotion-confirmation-ilr/promotion-confirmation-ilr.component';
import { PromotionAdviceThirstyComponent } from './thirsty-camel-reports/promotion-advice-thirsty/promotion-advice-thirsty.component';
import { PosPressBriefReportThirstyComponent } from './thirsty-camel-reports/pos-press-brief-report-thirsty/pos-press-brief-report-thirsty.component';
import { NewMemberAdviceComponent } from './ilr-reports/new-member-advice/new-member-advice.component';
import { RangeFocusExportComponent } from './range-focus-export/range-focus-export.component';
import { PromotionMixMatchComponent } from './promotion-mix-match/promotion-mix-match.component';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css'],
  providers: [ReportService]
})
@Injectable({
  providedIn: 'root'
})
export class ReportsComponent implements OnInit {
  @ViewChild('listComponent', { static: false }) listComponent: any;
  @ViewChild(DxScrollViewComponent, { static: false }) scrollView: DxScrollViewComponent;
  dataSource: any;
  tabs: Tab[];
  activeIcon = 'favorites';
  showFilter: boolean = false;
  showReportPopup: boolean = false;
  showCategoryTabs: boolean = true;
  reportName: string;
  reportComponent: string;
  reports: Report[];
  favoriteReport: favoriteReport;
  private reportFilter: string = 'All';
  private reportType: string = 'standard';
  reportTitle: string;
  promoDescription:string;
 
  private componentMap: { [key: string]: Type<any> } = {
    'AlmAdviceComponent': AlmAdviceComponent,
    'PromotionSummary': PromotionSummary,
    'PromotionSummaryThirsty': PromotionSummaryThirsty,
    'BuyInTheGreenComponent': BuyInTheGreenComponent,
    'PromotionConfirmationIlrComponent': PromotionConfirmationIlrComponent,
    'PromotionAdviceComponent': PromotionAdviceComponent,
    'CoreRangeComponent': CoreRangeComponent,
    'MemberAdviceComponent': MemberAdviceComponent,
    'NewMemberAdviceComponent': NewMemberAdviceComponent,
    'PromotionDetailsExportComponent': PromotionDetailsExportComponent,
    'ConfigurationComponent': ConfigurationComponent,
    'PromotionConfirmationComponent': PromotionConfirmationComponent,
    'PosPressBriefReportComponent': PosPressBriefReportComponent,
    'PromotionAdviceThirstyComponent': PromotionAdviceThirstyComponent,
    'PosPressBriefReportThirstyComponent': PosPressBriefReportThirstyComponent,
    'RangeFocusExportComponent': RangeFocusExportComponent,
    'PromotionMixMatchComponent': PromotionMixMatchComponent
  };
  excelReports = ["Promotion Details Export", "ALM Advice", "Pos & Press Brief Report", "Rebate & Focus Export"];
  pdfReports = [
    "ALM Advice", 
    "Buy in the Green", 
    "Core Range", 
    "Pos & Press Brief Report", 
    "Promotion Advice", 
    "Promotion Confirmation", 
    "Promotion Summary",
    "Members Advice",
    "Promotion Mix n Match"
  ];
  componentName:any;
  private apiUrl: string = environment.baseApiUrl;
 @ViewChild('reportContainer', { read: ViewContainerRef }) reportContainer: ViewContainerRef;

  constructor(private service: ReportService,
    private crudService: CrudService, ) {

     
    }
    currentComponentRef: ComponentRef<any>;
  async ngOnInit() {
    
    this.reports = await this.service.getReports();
    console.log(this.reports)
    this.dataSource = new DataSource ({
      store: this.reports,//this.filterReports(this.reportFilter, this.reportType),
      group: ["category"],
      searchOperation: "contains",
      searchExpr: "name",
      
    });
    this.dataSource.sort({selector:"name", desc:false});
    //this.tabs = this.service.getTabs();
  }
  loadReportComponent(componentToLoad: string, reportName: string) {
    const componentType = this.getComponentType(componentToLoad);
    if (this.currentComponentRef) {
      this.currentComponentRef.destroy();
    }
    this.currentComponentRef = this.reportContainer.createComponent(componentType);
    this.currentComponentRef.instance.name = reportName;
    this.currentComponentRef.changeDetectorRef.detectChanges();
  }


  async loadReport() {
      await this.loadReportComponent(this.componentName, this.reportName);
    }

    filter(event: any) {
      this.reportFilter = event.itemData.text;
      if(event.itemData.text === 'All') {
        this.reportFilter = 'All'
      }
      this.dataSource = new DataSource ({
        store: this.reports,//this.filterReports(event.itemData.text, this.reportType),
        group: "category",
        searchOperation: "contains",
        searchExpr: "name"
      });
      this.dataSource.sort();
    }

    byReportType(event: any) {
      let favorites = 0;
      this.reportType = event.itemData.text.toLowerCase();
      console.log(this.reportType);
      favorites = this.reportType === 'favorites' ?  1 : 0;
      this.dataSource = new DataSource ({
        store: this.reports,//this.filterReports(this.reportFilter, this.reportType, favorites),
        group: "category",
        searchOperation: "contains",
        searchExpr: "name"
      })
      this.dataSource.sort();
    }

    toggleFavoriteReport(data: any): void {
      const userDetails: UserDetails = JSON.parse(localStorage.getItem('userDetails') || '{}');
      const favoriteData: favoriteReport = {
        group_id: userDetails.data.group_id,
        location_id: userDetails.data.location_id,
        user_id: userDetails.data.id,
        report_id: data.id,
        type: data.type
      };
      if(data.favorite) {
        this.addFavorite(favoriteData);
      } else {
        this.removeFavorite(favoriteData);
      }
      data.favorite = !data.favorite;
    }
    getComponentType(componentName: string): Type<any> {
      const componentType = this.componentMap[componentName];
      if (!componentType) {
        throw new Error(`No component found for name ${componentName}`);
      }
      return componentType;
    }
    showReportFilter(report: string, component: string) {
      this.componentName = component;
      this.reportName = report;
      this.showReportPopup = true;
    }

    filterReports(filter: string, type: string, favorite?: number): Report[]
    {
      let filteredReports: Report[];
      if (favorite) {
        this.showCategoryTabs = false;
        return filteredReports = this.reports.filter(report => report.favorite === 1);
      } else if (filter === 'All') {
          this.showCategoryTabs = true;
          return filteredReports = this.reports.filter(report => report.type === type);
      }
      else {
          this.showCategoryTabs = true;
          filteredReports = this.reports.filter(report => report.type === type && report.category === filter);
          return filteredReports;
      }
    }

    addFavorite(data: favoriteReport) {
      this.crudService.insertData(`${this.apiUrl}favorite`, data).subscribe();
    }

    removeFavorite(data: favoriteReport) {
      this.crudService.deleteDataWithBody(`${this.apiUrl}favorite`, data).subscribe();
    }
}
