import { Component, ViewChild } from '@angular/core';
import { ConfirmationService } from 'src/app/services/confirmation.service';
import { HorizontalMenuService } from 'src/app/services/horizontalmenu.service';
import { DxDataGridComponent } from 'devextreme-angular';
import { CrudService } from 'src/app/services/crud.service';
import CustomStore from 'devextreme/data/custom_store';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-media-types',
    templateUrl: './media-types.component.html',
    styleUrls: ['./media-types.component.css'],
    standalone: false
})
export class MediaTypesComponent {
  public dataSource: any;
  private apiUrl: string = environment.baseApiUrl;
  editMediaTypes: boolean;
  fieldTypes: any = ['Number', 'Text', 'Checkbox'];
  @ViewChild("mediaTypesGrid", { static: false }) public dataGrid: DxDataGridComponent;

  constructor(private confirmationService:ConfirmationService,
    private horizontalMenuService:HorizontalMenuService,
    private crudService: CrudService) {
      this.dataSource = new CustomStore<any>({
        key: 'id',
        load: async () => {
          return await new Promise((resolve) => {
            this.crudService.getData(`${this.apiUrl}media-types`).subscribe((result: any) => {
              const userDetails = localStorage.getItem('userDetails');
              const parsedDetails = JSON.parse(userDetails || '{}');
              localStorage.setItem('media-types', JSON.stringify(result));
              resolve({data: result, totalCount: result.length});
            })
          })
        },
        insert: async (values) => {
          const userDetails = localStorage.getItem('userDetails');
          const parsedDetails = JSON.parse(userDetails || '{}');
          values.group_id = parsedDetails.data.group_id;
          values.location_id = parsedDetails.data.location_id;
          return await new Promise(async (resolve) => {
            const result: any = await this.crudService.insertData(`${this.apiUrl}media-types`, values)
            .subscribe((result: any) => {
              resolve(result);
            });
          });
        },
        update: async (key, values) => {
          return await new Promise(async (resolve) => {
            const result: any = await this.crudService.updateData(`${this.apiUrl}media-types`, key, values)
            .subscribe((result: any) => {
              resolve(result);
            });
          });
        },
        remove: async (key) => {
          return await new Promise(async (resolve) => {
            const result: any = await this.crudService.deleteData(`${this.apiUrl}media-types`, key)
            .subscribe((result: any) => {
              resolve(result);
            });
          });
        }
      });

      this.horizontalMenuService.editMediaTypes$.subscribe((value: boolean) => {
        this.editMediaTypes = value;
      });
  }
  onHiding(event:any){
    this.confirmationService.checkUnsaved(event, this.dataGrid, (value:boolean) => this.horizontalMenuService.seteditMediaTypes(value))
  }
}
