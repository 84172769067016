import { ChangeDetectorRef, Component, OnInit, Type, ViewChild, isDevMode } from '@angular/core';
import { CrudService } from './services/crud.service';
import { Config } from './models/config.model';
import { environment } from 'src/environments/environment';
import { Event, NavigationEnd, Router } from '@angular/router';
import { DxTreeViewComponent } from 'devextreme-angular';
import { BuyingGroups } from './models/buying-groups.model';
import { CookieService } from 'ngx-cookie-service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css'],
    standalone: false
})

export class AppComponent implements OnInit {
  @ViewChild(DxTreeViewComponent) treeView: DxTreeViewComponent;
  title = 'promoFlo';
  selectedItem: any;
  isDrawerOpen: boolean = false;
  private apiUrl = environment.baseApiUrl;
  isMaintenanceRoute = false;
  constructor(private router: Router,
    private crudService: CrudService,
    private cdr: ChangeDetectorRef,
    private cookieService: CookieService) {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.isMaintenanceRoute = event.urlAfterRedirects === '/maintenance';
      }
    });
    this.selectedItem = this.navigation[0].component; // Default to first item's component
  }
  ngOnInit(): void {
    if (isDevMode()) {
      console.log('Development Mode');
    } else {
      console.log('Production Mode');
    }
    this.getHostFileData();
  }

  getHostFileData() {
    this.crudService.getData(`${this.apiUrl}config`).subscribe(async (data: any) => {
      console.log('Config Data: ', data);
      sessionStorage.setItem('ConfigData', JSON.stringify(data));
      const userDetails = localStorage.getItem('userDetails');
      const parsedDetails = JSON.parse(userDetails || '{}');
      const dataArray = Object.values(data);
      let lastUpdate = '';
      const foundConfig: Config = dataArray.find((config: any) => config.config_key === 'LastHostFileUpdate') as Config;
      if (foundConfig) {
        lastUpdate = foundConfig.config_string;
      } else {
        lastUpdate = '2023-09-11';
      }
      if(lastUpdate < new Date().toISOString().split('T')[0]) {
        const response: BuyingGroups[] = await this.crudService.getData(`${this.apiUrl}buying-groups`).toPromise();
        const data = {
          main_supplier_token: parsedDetails.data.location_token || '',
          date_added: lastUpdate
        }
        response.forEach(BuyingGroup => {
          this.crudService.updateData(`${this.apiUrl}host-data`, BuyingGroup.account_code, data).subscribe((response: any) => {
            console.log('products pricing: ', response);
          });
        });
        this.crudService.getData(`${this.apiUrl}host-data?date_added=${lastUpdate}`).subscribe((hostData: any) => {
          console.log('Host Data: ', hostData);
          if(hostData && hostData.success) {
            const configData = {
              group_id: parsedDetails.data.group_id,
              location_id: parsedDetails.data.location_id,
              config_key: 'LastHostFileUpdate',
              config_string: new Date().toISOString().split('T')[0]
            };
            this.crudService.updateData(`${this.apiUrl}config`, 1, configData).subscribe();
          }
        }, (error: any) => {
          console.log('Error fetching host data: ', error);
        });
      }
    });
  }
  navigation: any[] = [
    { id: 0, text: "Home", icon: "home", path: '/' },
    { id: 1, text: "Planning", icon: "event", items: [
      {
        id: 11,
        text: 'Slotting',
        path: 'slotting'

      },
      {
        title: "Promotions",
        id: 12,
        text: 'Promotions',
        path: 'promotions'
      },
      {
        title: "Product Core Range",
        id: 13,
        text: 'Core Range',
        path: 'core-range'
      },
      {
        title: "Warehouse Pricing",
        id: 14,
        text: 'Warehouse Pricing',
        path: 'warehouse-pricing'
      }
    ]

     },
    { id: 2, text: "Reports", icon: "textdocument", path: "reports" },
    { id: 3, text: "Configuration", icon: "preferences", items: [
      {
        id: 31,
        text: 'Products',
        path: 'products'
      },
      {
        id: 32,
        text: 'Promotions',
        items: [
          {
            title: "Names",
            id: 321,
            text: 'Names',
            path: 'promotion-names'
          },
          {
            id: 322,
            text: 'Groups',
            path: 'promotion-groups'

          },
          {
            title: "Types",
            id: 323,
            text: 'Types',
            path: 'promotion-types'
          },

          {
            title: "Periods",
            id: 324,
            text: 'Periods',
            path: 'promotion-periods'
          },

          {
            title: "Status",
            id: 325,
            text: 'Status',
            path: 'promotion-status'
          },

          {
            title: "Allowances",
            id: 326,
            text: 'Allowances',
            path: 'promotion-allowances'
          },
          {
            title: "Categories",
            id: 327,
            text: 'Categories',
            path: 'promotion-categories'
          },
          {
            title: "Buying Dates",
            id: 328,
            text: 'Buying Dates',
            path: 'promotion-buying-dates'
          }
      ]},
      {
        id: 4,
        text: 'Other',
        items: [
          {
            title: "Slotting Types",
            id: 41,
            text: 'Slotting Types',
            path: 'slotting-types'
          },
          {
            title: "Buying Groups",
            id: 42,
            text: 'Buying Groups',
            path: 'buying-groups'
          },

          {
            title: "Events",
            id: 43,
            text: 'Events',
            path: 'events'
          },
          {
            title: "Media Types",
            id: 44,
            text: 'Media Types',
            path: 'media-types'
          },
          {
            title: "Store Positions",
            id: 45,
            text: 'Store Positions',
            path: 'store-positons'
          },
          {
            title: "Warehouses",
            id: 46,
            text: 'Warehouses',
            path: 'warehouses'
          },
          {
            title: "Host Data",
            id: 47,
            text: 'Host Data',
            path: 'raw-host-data'
          }
        ]
      }
    ] }
];
  toggleDrawer() {
    this.isDrawerOpen = !this.isDrawerOpen;
    console.log(this.isDrawerOpen);
    this.treeView.instance.collapseAll();
    this.cdr.detectChanges();
  }
  onOutsideClick = (e:any) => {
    // Check if the drawer is open
    if (this.isDrawerOpen) {
      // Close the drawer
      this.isDrawerOpen = false;
      // Collapse the tree view nodes
      this.collapseTreeView();
      this.cdr.detectChanges();
    }
    // Return true to proceed with the default outside click behavior
    return true;
  };
  collapseTreeView() {
    console.log(this.treeView)
    if (this.treeView && this.treeView.instance) {
      this.treeView.instance.collapseAll();
    }
  }
  selectItem(item: any) {
    if(!Object.keys(item.itemData).includes('items')){
      this.isDrawerOpen = !this.isDrawerOpen;
    }
    if('path' in item.itemData){
      this.router.navigate([item.itemData.path]);
      this.isDrawerOpen = !this.isDrawerOpen;
    } else {
      this.selectedItem = item.itemData.component;
      if(!this.isDrawerOpen){
        this.isDrawerOpen = !this.isDrawerOpen;
      }
    }
    this.cdr.detectChanges();
  }
}

export class Tab {
  id: number;
  text: string;
  path: string;
}

