import { Component, OnInit, ViewChild } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CrudService } from 'src/app/services/crud.service';
import DataSource from 'devextreme/data/data_source';
import ArrayStore from 'devextreme/data/array_store';
import { Router } from '@angular/router';
import notify from 'devextreme/ui/notify';
import { DxPopupComponent, DxDataGridComponent } from 'devextreme-angular';
import { RebateNames } from 'src/app/models/rebate-names.model';
import { HorizontalMenuService } from 'src/app/services/horizontalmenu.service';
import { CostServiceService } from 'src/app/services/cost-service.service';
import { Product } from 'src/app/models/product.model';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
import { exportDataGrid } from 'devextreme/excel_exporter';

@Component({
    selector: 'app-core-range-details',
    templateUrl: './core-range-details.component.html',
    styleUrls: ['./core-range-details.component.css'],
    standalone: false
})
export class CoreRangeDetailsComponent implements OnInit {
  @ViewChild('productSelection', {static: false}) productSelection: DxPopupComponent;
  @ViewChild(DxDataGridComponent, { static: false }) public dataGrid: DxDataGridComponent;
  coreRangeDetailsLoading: boolean = true;
  productDataSource: DataSource;
  parent: string = 'core-range-details'
  key: number = 0;
  header: any;
  rebateNames: RebateNames[] = [];
  columns: any[] = [];
  currencyFormat = { type: 'currency', precision: 2 };
  permissions: boolean = true;
  disabled: boolean = false;
  copyPricing: boolean = false;
  private apiUrl: string = environment.baseApiUrl;

  constructor(private crudService: CrudService,
    private router: Router,
    private horizontalMenuService: HorizontalMenuService,
    private costService: CostServiceService) {
      this.coreRangeDetailsLoading = true;
      if(localStorage.getItem('core-range-header') !== null) {
        const header = JSON.parse(localStorage.getItem('core-range-header') || '{}');
        this.header = header;
        this.key = header.id;
      }
      if(this.header.status_type === 'closed') {
        this.disabled = true;
      }
  }
ngOnInit(): void {
  this.loadData();
}
  // contentReadyLoading(event:any){
  //   this.coreRangeDetailsLoading = false; // End loading
  // }
  async loadData()
  {
    this.coreRangeDetailsLoading = true;
    this.copyPricing = false;
    console.log(`Core Range Header`, this.header);
    await this.crudService.getData(`${this.apiUrl}core-range-details?core_range_header_id=${this.header.id}&buying_group_id=${this.header.buying_group_id}`).subscribe((products: any) => {
      this.productDataSource = new DataSource({
      store: new ArrayStore({
        data: products.data,
        key: ['id']
      }),
      filter: [['core_range_header_id', '=', this.key]]
      });
    }, (error: any) => {
      console.log('Error fetching core range details: ', error);
    });
   await this.crudService.getData(environment.baseApiUrl + 'rebates?type=1').toPromise()
        .then((rebateNames: any) => {
          this.rebateNames = rebateNames.data;
          this.addColumn();
          this.coreRangeDetailsLoading = false;
        })
        .catch((error: any) => {
          console.log('Error fetching rebate names: ', error);
      });
  }

  onRowUpdating(e: any)
  {
    const rebateData: any[] = [];
    const keys = Object.keys(e.newData);
    keys.forEach((key: any) => {
      const rebateDetails = this.rebateNames.find((rebate) => rebate.rebate_name === key);
      if(rebateDetails) {
        rebateData.push({
          rebate_id: rebateDetails.id,
          rebate_amount: e.newData[key]
        });
      }
    });
    const data = { ...e.newData , rebates: [...rebateData]};
    this.crudService.updateData(`${this.apiUrl}core-range-details`, e.key.id, data)
      .subscribe((result: any) => {
        if(result.success) {
          notify(result.data, 'success', 200);
        } else {
          notify(result.data, 'error', 500);
        }
      });
  }

  onRowRemoving(e: any)
  {
    this.crudService.deleteData(`${this.apiUrl}core-range-details`, e.key.id)
      .subscribe((result: any) => {
        if(result.success) {
          notify(result.data, 'success', 500);
        } else {
          notify(result.data, 'error', 500);
        }
      })
  }

  calculateGpAmount = (row: Product) =>
  {
    return this.costService.calculateGpAmount(row);
  }

  calculateGpPercent = (row: Product) =>
  {
    return this.costService.calculateGpPercent(row);
  }

  calculateNewCost = (row: Product) =>
  {
    return this.costService.calculateNewCost(row);
  }

  calculateNUCost = (row: Product) =>
  {
    const newCost = this.costService.calculateNewCost(row) || 0;
    return newCost / row.packs_per_carton;
  }

  openProductSelection = (e: any) => {
    this.productSelection.instance.show();
  }

  closeProductSelection = () => {
    this.productSelection.instance.hide();
  }

  returnToHeader = (e:any) =>{
    this.router.navigate(['/core-range']);
  }

  addColumn() {
    this.columns = [];
    this.rebateNames.forEach((rebate: RebateNames) => {
       this.columns.push({
          dataField: rebate.rebate_name,
          dataType: 'number',
          format: {
            type: 'currency',
            precision: 2
          }
        });
      });
    }

    onEditingStart(e: any) {
      this.rebateNames.forEach((rebate: RebateNames) => {
        if(e.column.dataField === rebate.rebate_name) {
          localStorage.setItem('coreSelectedRebateColumn', rebate.rebate_name);
        }
      });
    }

    rebatePopupEvent(e: any){
      const nativeEvent = e.event || e;
      if (nativeEvent.stopPropagation) {
        nativeEvent.stopPropagation();
      }
      this.horizontalMenuService.seteditPromotionRebates(true);
    }

    sanitizeSheetName(name: string): string {
      return name.replace(/[:\\/?*\[\]]/g, '_');
    }

    onExporting(e: any) {
      console.log('Header: ',this.header);
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet(this.sanitizeSheetName(this.header.range_name));

      exportDataGrid({
        component: e.component,
        worksheet,
        autoFilterEnabled: true,
        customizeCell: ({ gridCell, excelCell }) => {
          if(gridCell?.rowType === 'header') {
            excelCell.font = { bold: true };
            excelCell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '6ABDC8' } };
          }
        }
      }).then(() => {
        workbook.xlsx.writeBuffer().then((buffer) => {
          saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `Core Range ${this.sanitizeSheetName(this.header.range_name)}.xlsx`);
        });
      });
    }

    updateCosts() {
      this.crudService.getData(`${this.apiUrl}update-core-range-costs?core_range_header_id=${this.header.id}&buying_group_id=${this.header.buying_group_id}`).subscribe((result:any) => {
        if(result.success) {
          this.dataGrid.instance.refresh();
          notify({ message: 'Update complete' }, 'success');
        } else {
          notify({ message: 'Update failed' }, 'error');
        }
      }
      );
    }

    openCopySelection = (e: any) => {
      console.log(e);
      this.copyPricing = !this.copyPricing;
      //this.cdr.detectChanges();
    }

    closeCopySelection = () => {
      this.copyPricing = false;
      this.dataGrid.instance.refresh();
    }

}

export class CoreRangeDetail
{
  id?: number;
  core_range_header_id: number;
  product_id: number;
  product_name: string;
  cost_inc_gst: number;
  sell_price: number;
  standard_price: number;
}
